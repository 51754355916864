import { createContext, useState } from 'react';
import {
    ICommunityContext,
    IPublicAnswers,
    IPublicQuestionAnswers,
    IQuestions,
} from '../../../interfaces';
import answersAPI from '../../../api/answers.api';
import { CHAPTERS } from '../../../constants/chapters';
import { QUESTIONS } from '../../../constants/questions.constant';
import { PublicAllQuestionMatchAnswers, PublicQuestionMatchAnswers } from '../../../utils/questions.util';

interface Props {
    children: React.ReactNode;
}

const initialContext = {
    loading: false,
    loadingAnswers: false,
    publicAnswers: [],
    publicQuestionAnswers: {} as IPublicQuestionAnswers,
    stats: [],
    chapterSelected: 'RECTORY_CHALLENGE',
    questions: QUESTIONS,
    setChapterSelected: () => {},
    setPublicAnswers: () => {},
    fetchPublicAnswers: () => {},
    fetchStats: () => {},
    setQuestions: () => {},
};

export const CommunityContext = createContext<ICommunityContext>(initialContext);

export const CommunityProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [loadingAnswers, setLoadingAnswers] = useState<boolean>(initialContext.loadingAnswers);
    const [publicAnswers, setPublicAnswers] = useState<IPublicAnswers[]>(initialContext.publicAnswers);
    const [publicQuestionAnswers, setPublicQuestionAnswers] = useState<IPublicQuestionAnswers>(initialContext.publicQuestionAnswers);
    const [stats, setStats] = useState<any>(initialContext.stats);
    const [chapterSelected, setChapterSelected] = useState<string>(initialContext.chapterSelected);
    const [questions, setQuestions] = useState<IQuestions[]>(initialContext.questions);

    const fetchStats = async () => {
        setLoading(true);
        answersAPI.getAllAnswers()
            .then(({ data }: any) => {
                data.forEach((itemData: any) => {
                    const chapterFounded = CHAPTERS.find((item: any) => item.keyName === itemData.chapter);
                    if (chapterFounded) {
                        chapterFounded.totalAnswers = itemData?.answers?.length;
                    }
                });
                setStats(CHAPTERS);
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    const fetchPublicAnswers = async () => {
        setLoadingAnswers(true);
        const params = {
            limit: 100,
            page: 1,
            chapter: chapterSelected,
        };

        answersAPI.getPublicAnswers(params)
            .then(({ data }: any) => {
                const questionsMatched = PublicQuestionMatchAnswers(data.data, questions, chapterSelected);
                const allMatched = PublicAllQuestionMatchAnswers(questionsMatched, questions, chapterSelected);
                setPublicQuestionAnswers(allMatched);
                setPublicAnswers(data);
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoadingAnswers(false));
    };

    return (
        <CommunityContext.Provider
            value={{
                loading,
                loadingAnswers,
                publicAnswers,
                publicQuestionAnswers,
                stats,
                questions,
                chapterSelected,
                setChapterSelected,
                setPublicAnswers,
                fetchPublicAnswers,
                fetchStats,
                setQuestions,
            }}
        >
            {children}
        </CommunityContext.Provider>
    );
};
