import { CALENDAR_API_KEY } from '../../config';

const Calendar = () => {
    const frame = `https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FGuayaquil&showPrint=0&mode=MONTH&showTabs=0&showCalendars=0&src=${CALENDAR_API_KEY}&color=%23C0CA33`;

    return (
        <div className="home__section--schedule-calendar">
            <iframe
                id="calendar"
                title="calendar"
                src={frame}
                style={{ border: 0, backgroundColor: 'transparent' }}
                className="home__section--schedule-calendar-frame"
            />
        </div>
    );
};

export default Calendar;
