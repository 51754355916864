import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
    form: any;
    group: any;
}

const { Item } = Form;

const CreateGroup = ({ form, group }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const RULE = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { min: 3, message: tf('RULES.MIN_LENGTH', { length: 3 }) },
    ];

    return (
        <Form
            form={form}
            layout="vertical"
            name="create-group"
        >
            <Item
                name="nameGroup"
                rules={RULE}
                label={tf('INPUTS.STUDY_GROUPS.NAME_GROUP')}
            >
                <Input placeholder={tf('INPUTS.STUDY_GROUPS.NAME_GROUP_PLACEHOLDER')} />
            </Item>
        </Form>
    );
};

export default CreateGroup;
