import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';

import Layout from '../components/Layout';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import {
    Community, Dashboard, Home, Login, Onboarding, Schedule, ValidateOTP,
} from '../screens';

import { STEPS_ONBOARDING } from '../constants/steps';

import PublicRoute from './PublicRoute';
import LayoutDashboard from '../components/Layout/Dashboard';
import StepsManager from '../components/Onboarding/StepsManager';
import PrivateRoute from './PrivateRoute';

export const AppRouter = () => (
    <Router>
        <Routes>
            <Route
                path="/"
                element={(
                    <PublicRoute>
                        <Layout>
                            <Navbar />
                            <Home />
                            <Footer />
                        </Layout>
                    </PublicRoute>
                )}
            />
            <Route
                path="/schedule"
                element={(
                    <PublicRoute>
                        <Layout>
                            <Navbar />
                            <Schedule />
                            <Footer />
                        </Layout>
                    </PublicRoute>
                )}
            />
            <Route
                path="/groups/login"
                element={(
                    <PublicRoute>
                        <LayoutDashboard>
                            <Login />
                        </LayoutDashboard>
                    </PublicRoute>
                )}
            />
            <Route
                path="/verified-group"
                element={(
                    <PublicRoute>
                        <LayoutDashboard>
                            <ValidateOTP />
                        </LayoutDashboard>
                    </PublicRoute>
                )}
            />
            <Route
                path="/groups/onboarding"
                element={(
                    <PublicRoute>
                        <LayoutDashboard>
                            <Onboarding />
                        </LayoutDashboard>
                    </PublicRoute>
                )}
            />
            <Route
                path="/groups/onboarding/:step"
                element={(
                    <PublicRoute>
                        <LayoutDashboard>
                            <StepsManager steps={STEPS_ONBOARDING} />
                        </LayoutDashboard>
                    </PublicRoute>
                )}
            />
            <Route
                path="/groups/dashboard/*"
                element={(
                    <PrivateRoute>
                        <LayoutDashboard>
                            <Dashboard />
                        </LayoutDashboard>
                    </PrivateRoute>
                )}
            />
            <Route
                path="/community"
                element={(
                    <LayoutDashboard>
                        <Community />
                    </LayoutDashboard>
                )}
            />
        </Routes>
    </Router>
);

export default AppRouter;
