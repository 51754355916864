import moment from 'moment';
import {
    Avatar, List, Space, Typography,
} from 'antd';
import { COUNTRIES } from '../../utils/countries.util';

const { Title, Text } = Typography;

interface Props {
    country: string;
    city: string;
    place: string;
    eventDate: Date;
}

const Location = ({
    country, city, place, eventDate,
}: Props) => {
    const findImage = (country: string) => COUNTRIES.filter((item: any) => item.code === country)[0].flag;
    return (
        <div>
            <List.Item.Meta
                avatar={(
                    <Avatar
                        size={64}
                        src={findImage(country)}
                    />
                )}
                title={(
                    <Title
                        level={4}
                        className="home__section--feedback-card-title"
                        style={{ marginBottom: 0 }}
                    >
                        {city}
                    </Title>
                )}
                description={(
                    <Space direction="vertical" size={8}>
                        <Text className="home__section--feedback-card-description">
                            {place}
                        </Text>
                        <Text className="home__section--feedback-card-date">
                            {moment(eventDate).format('lll')}
                        </Text>
                    </Space>
                )}
            />
        </div>
    );
};

export default Location;
