import {
    Button, PageHeader, Space, Typography,
} from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Chapter from './Chapter';
import { CommunityContext } from './context/communityContext';

const { Title, Text } = Typography;

const MainCommunity = () => {
    const { t } = useTranslation('dashboard', { keyPrefix: 'COMMUNITY' });
    const { t: th } = useTranslation('home', { keyPrefix: 'HOME' });

    const navigate = useNavigate();

    const {
        stats,
        fetchStats,
        chapterSelected,
        fetchPublicAnswers,
    } = useContext(CommunityContext);

    useEffect(() => {
        fetchStats();
    }, []);

    useEffect(() => {
        fetchPublicAnswers();
    }, [chapterSelected]);

    return (
        <div className="community full__size">
            <div className="community__inner">
                <PageHeader
                    title={(
                        <Title level={3} className="community__text-header" style={{ marginBottom: 0 }}>
                            {t('HEADER')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            className="question__actions-btn"
                            onClick={() => navigate('/', { replace: true })}
                        >
                            {t('EXIT')}
                        </Button>
                    )}
                    style={{
                        padding: 0,
                        width: '100%',
                        marginBottom: 24,
                        backgroundColor: 'transparent',
                    }}
                />
                <Space direction="vertical" size={12}>
                    <Text className="community__text-description">
                        {t('DESCRIPTION')}
                    </Text>
                    <Text className="community__text-description">
                        {t('ABOUT')}
                    </Text>
                </Space>
                <div className="community__content">
                    {
                        stats.map((chapter, index) => (
                            <Chapter
                                t={th}
                                index={index}
                                chapter={chapter}
                                key={index}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default MainCommunity;
