import { Col, Row } from 'antd';
import { IMember } from '../../interfaces';
import MemberCard from '../../widgets/MemberCard';

interface Props {
    members: IMember[];
}

const Members = ({ members }: Props) => (
    <Row gutter={[24, 20]} style={{ width: '100%' }}>
        {
            members.map((member: IMember, index: number) => (
                <Col xs={24} lg={12} xxl={12}>
                    <MemberCard key={index} member={member} />
                </Col>
            ))
        }
    </Row>
);

export default Members;
