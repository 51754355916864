import { Button, Form, Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../store/slices/auth';

const LoginForm = () => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const RULES_EMAIL: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { type: 'email', message: tf('RULES.INVALID_EMAIL') },
        { type: 'string', min: 3, message: tf('RULES.MIN_LENGTH', { length: 3 }) },
    ];

    const onSubmit = (values: any) => {
        dispatch(loginUser(values));
        navigate('/groups/dashboard/home');
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
        >
            <Form.Item
                label={tf('INPUTS.EMAIL')}
                name="email"
                rules={RULES_EMAIL}
            >
                <Input placeholder={tf('INPUTS.EMAIL_PLACEHOLDER')} />
            </Form.Item>

            <Form.Item
                label={tf('INPUTS.CODE_GROUP')}
                name="code"
                rules={RULES}
            >
                <Input placeholder={tf('INPUTS.CODE_GROUP_PLACEHOLDER')} />
            </Form.Item>

            <Form.Item
                label={tf('INPUTS.PASSWORD')}
                name="password"
                rules={RULES}
            >
                <Input.Password
                    placeholder={tf('INPUTS.PASSWORD_PLACEHOLDER')}
                    visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                />
            </Form.Item>

            <Button
                block
                type="primary"
                loading={loading}
                htmlType="submit"
            >
                {tf('ACTIONS.LOGIN')}
            </Button>

        </Form>
    );
};

export default LoginForm;
