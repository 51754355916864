import { IQuestions } from '../interfaces';

type TQuestions = IQuestions[];

export const QUESTIONS: TQuestions = [
    {
        chapter: 'CHAPTERS.RECTORY_CHALLENGE',
        chapterKeyName: 'RECTORY_CHALLENGE',
        section: 'SECTIONS.RECTORY_CHALLENGE',
        block: [
            {
                questions: [
                    {
                        keyName: 'QUESTION_1',
                        text: 'QUESTIONS.RECTORY_CHALLENGE.QUESTION_1',
                    },
                ],
            },
            {
                questions: [
                    {
                        keyName: 'QUESTION_2',
                        text: 'QUESTIONS.RECTORY_CHALLENGE.QUESTION_2',
                    },
                ],
            },
            {
                questions: [
                    {
                        keyName: 'QUESTION_3',
                        text: 'QUESTIONS.RECTORY_CHALLENGE.QUESTION_3',
                    },
                ],
            },
            {
                questions: [
                    {
                        keyName: 'QUESTION_4',
                        text: 'QUESTIONS.RECTORY_CHALLENGE.QUESTION_4',
                    },
                ],
            },
            {
                questions: [
                    {
                        keyName: 'QUESTION_5',
                        text: 'QUESTIONS.RECTORY_CHALLENGE.QUESTION_5',
                    },
                ],
            },
        ],
    },
    {
        chapter: 'CHAPTERS.CHAPTER_1',
        chapterKeyName: 'CHAPTER_1',
        block: [
            {
                section: 'SECTIONS.CHAPTER_1.SECTION_1',
                questions: [
                    {
                        keyName: 'QUESTION_1',
                        text: 'QUESTIONS.CHAPTER_1.QUESTION_1',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_1.SECTION_2',
                questions: [
                    {
                        keyName: 'QUESTION_2',
                        text: 'QUESTIONS.CHAPTER_1.QUESTION_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_1.SECTION_3',
                questions: [
                    {
                        keyName: 'QUESTION_3',
                        text: 'QUESTIONS.CHAPTER_1.QUESTION_3',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_1.SECTION_4',
                questions: [
                    {
                        keyName: 'QUESTION_4',
                        text: 'QUESTIONS.CHAPTER_1.QUESTION_4',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_1.SECTION_5',
                questions: [
                    {
                        keyName: 'QUESTION_5_1',
                        text: 'QUESTIONS.CHAPTER_1.QUESTION_5_1',
                    },
                    {
                        keyName: 'QUESTION_5_2',
                        text: 'QUESTIONS.CHAPTER_1.QUESTION_5_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_1.SECTION_6',
                questions: [
                    {
                        keyName: 'QUESTION_6',
                        text: 'QUESTIONS.CHAPTER_1.QUESTION_6',
                    },
                ],
            },
        ],
    },
    {
        chapter: 'CHAPTERS.CHAPTER_2',
        chapterKeyName: 'CHAPTER_2',
        block: [
            {
                section: 'SECTIONS.CHAPTER_2.SECTION_1',
                questions: [
                    {
                        keyName: 'QUESTION_1_1',
                        text: 'QUESTIONS.CHAPTER_2.QUESTION_1_1',
                    },
                    {
                        keyName: 'QUESTION_1_2',
                        text: 'QUESTIONS.CHAPTER_2.QUESTION_1_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_2.SECTION_2',
                questions: [
                    {
                        keyName: 'QUESTION_2',
                        text: 'QUESTIONS.CHAPTER_2.QUESTION_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_2.SECTION_3',
                questions: [
                    {
                        keyName: 'QUESTION_3',
                        text: 'QUESTIONS.CHAPTER_2.QUESTION_3',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_2.SECTION_4',
                questions: [
                    {
                        keyName: 'QUESTION_4',
                        text: 'QUESTIONS.CHAPTER_2.QUESTION_4',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_2.SECTION_5',
                questions: [
                    {
                        keyName: 'QUESTION_5',
                        text: 'QUESTIONS.CHAPTER_2.QUESTION_5',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_2.SECTION_6',
                questions: [
                    {
                        keyName: 'QUESTION_6',
                        text: 'QUESTIONS.CHAPTER_2.QUESTION_6',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_2.SECTION_7',
                questions: [
                    {
                        keyName: 'QUESTION_7',
                        text: 'QUESTIONS.CHAPTER_2.QUESTION_7',
                    },
                ],
            },
        ],
    },
    {
        chapter: 'CHAPTERS.CHAPTER_3',
        chapterKeyName: 'CHAPTER_3',
        block: [
            {
                section: 'SECTIONS.CHAPTER_3.SECTION_1',
                questions: [
                    {
                        keyName: 'QUESTION_1_1',
                        text: 'QUESTIONS.CHAPTER_3.QUESTION_1_1',
                    },
                    {
                        keyName: 'QUESTION_1_2',
                        text: 'QUESTIONS.CHAPTER_3.QUESTION_1_2',
                    },
                    {
                        keyName: 'QUESTION_1_3',
                        text: 'QUESTIONS.CHAPTER_3.QUESTION_1_3',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_3.SECTION_2',
                questions: [
                    {
                        keyName: 'QUESTION_2_1',
                        text: 'QUESTIONS.CHAPTER_3.QUESTION_2_1',
                    },
                    {
                        keyName: 'QUESTION_2_2',
                        text: 'QUESTIONS.CHAPTER_3.QUESTION_2_2',
                    },
                    {
                        keyName: 'QUESTION_2_3',
                        text: 'QUESTIONS.CHAPTER_3.QUESTION_2_3',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_3.SECTION_3',
                questions: [
                    {
                        keyName: 'QUESTION_3',
                        text: 'QUESTIONS.CHAPTER_3.QUESTION_3',
                    },
                ],
            },
        ],
    },
    {
        chapter: 'CHAPTERS.CHAPTER_4',
        chapterKeyName: 'CHAPTER_4',
        block: [
            {
                section: 'SECTIONS.CHAPTER_4.SECTION_1',
                questions: [
                    {
                        keyName: 'QUESTION_1',
                        text: 'QUESTIONS.CHAPTER_4.QUESTION_1',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_4.SECTION_2',
                questions: [
                    {
                        keyName: 'QUESTION_2',
                        text: 'QUESTIONS.CHAPTER_4.QUESTION_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_4.SECTION_3',
                questions: [
                    {
                        keyName: 'QUESTION_3',
                        text: 'QUESTIONS.CHAPTER_4.QUESTION_3',
                    },
                ],
            },
        ],
    },
    {
        chapter: 'CHAPTERS.CHAPTER_5',
        chapterKeyName: 'CHAPTER_5',
        block: [
            {
                section: 'SECTIONS.CHAPTER_5.SECTION_1',
                questions: [
                    {
                        keyName: 'QUESTION_1',
                        text: 'QUESTIONS.CHAPTER_5.QUESTION_1',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_5.SECTION_2',
                questions: [
                    {
                        keyName: 'QUESTION_2_1',
                        text: 'QUESTIONS.CHAPTER_5.QUESTION_2_1',
                    },
                    {
                        keyName: 'QUESTION_2_2',
                        text: 'QUESTIONS.CHAPTER_5.QUESTION_2_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_5.SECTION_3',
                questions: [
                    {
                        keyName: 'QUESTION_3',
                        text: 'QUESTIONS.CHAPTER_5.QUESTION_3',
                    },
                ],
            },
        ],
    },
    {
        chapter: 'CHAPTERS.CHAPTER_6',
        chapterKeyName: 'CHAPTER_6',
        block: [
            {
                section: 'SECTIONS.CHAPTER_6.SECTION_1',
                questions: [
                    {
                        keyName: 'QUESTION_1',
                        text: 'QUESTIONS.CHAPTER_6.QUESTION_1',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_6.SECTION_2',
                questions: [
                    {
                        keyName: 'QUESTION_2_1',
                        text: 'QUESTIONS.CHAPTER_6.QUESTION_2_1',
                    },
                    {
                        keyName: 'QUESTION_2_2',
                        text: 'QUESTIONS.CHAPTER_6.QUESTION_2_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_6.SECTION_3',
                questions: [
                    {
                        keyName: 'QUESTION_3_1',
                        text: 'QUESTIONS.CHAPTER_6.QUESTION_3_1',
                    },
                    {
                        keyName: 'QUESTION_3_2',
                        text: 'QUESTIONS.CHAPTER_6.QUESTION_3_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_6.SECTION_4',
                questions: [
                    {
                        keyName: 'QUESTION_4',
                        text: 'QUESTIONS.CHAPTER_6.QUESTION_4',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_6.SECTION_5',
                questions: [
                    {
                        keyName: 'QUESTION_5',
                        text: 'QUESTIONS.CHAPTER_6.QUESTION_5',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_6.SECTION_6',
                questions: [
                    {
                        keyName: 'QUESTION_6',
                        text: 'QUESTIONS.CHAPTER_6.QUESTION_6',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_6.SECTION_7',
                questions: [
                    {
                        keyName: 'QUESTION_7',
                        text: 'QUESTIONS.CHAPTER_6.QUESTION_7',
                    },
                ],
            },
        ],
    },
    {
        chapter: 'CHAPTERS.CHAPTER_7',
        chapterKeyName: 'CHAPTER_7',
        block: [
            {
                section: 'SECTIONS.CHAPTER_7.SECTION_1',
                questions: [
                    {
                        keyName: 'QUESTION_1',
                        text: 'QUESTIONS.CHAPTER_7.QUESTION_1',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_7.SECTION_2',
                questions: [
                    {
                        keyName: 'QUESTION_2',
                        text: 'QUESTIONS.CHAPTER_7.QUESTION_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_7.SECTION_3',
                questions: [
                    {
                        keyName: 'QUESTION_3',
                        text: 'QUESTIONS.CHAPTER_7.QUESTION_3',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_7.SECTION_4',
                questions: [
                    {
                        keyName: 'QUESTION_4_1',
                        text: 'QUESTIONS.CHAPTER_7.QUESTION_4_1',
                    },
                    {
                        keyName: 'QUESTION_4_2',
                        text: 'QUESTIONS.CHAPTER_7.QUESTION_4_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_7.SECTION_5',
                questions: [
                    {
                        keyName: 'QUESTION_5',
                        text: 'QUESTIONS.CHAPTER_7.QUESTION_5',
                    },
                ],
            },
        ],
    },
    {
        chapter: 'CHAPTERS.CHAPTER_8',
        chapterKeyName: 'CHAPTER_8',
        block: [
            {
                section: 'SECTIONS.CHAPTER_8.SECTION_1',
                questions: [
                    {
                        keyName: 'QUESTION_1',
                        text: 'QUESTIONS.CHAPTER_8.QUESTION_1',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_8.SECTION_2',
                questions: [
                    {
                        keyName: 'QUESTION_2',
                        text: 'QUESTIONS.CHAPTER_8.QUESTION_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_8.SECTION_3',
                questions: [
                    {
                        keyName: 'QUESTION_3',
                        text: 'QUESTIONS.CHAPTER_8.QUESTION_3',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_8.SECTION_4',
                questions: [
                    {
                        keyName: 'QUESTION_4',
                        text: 'QUESTIONS.CHAPTER_8.QUESTION_4',
                    },
                ],
            },
        ],
    },
    {
        chapter: 'CHAPTERS.CHAPTER_9',
        chapterKeyName: 'CHAPTER_9',
        block: [
            {
                questions: [
                    {
                        keyName: 'QUESTION_1',
                        text: 'QUESTIONS.CHAPTER_9.QUESTION_1',
                    },
                    {
                        keyName: 'QUESTION_2',
                        text: 'QUESTIONS.CHAPTER_9.QUESTION_2',
                    },
                ],
            },
        ],
    },
    {
        chapter: 'CHAPTERS.CHAPTER_10',
        chapterKeyName: 'CHAPTER_10',
        block: [
            {
                questions: [
                    {
                        keyName: 'QUESTION_1',
                        text: 'QUESTIONS.CHAPTER_10.QUESTION_1',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_10.SECTION_1',
                questions: [
                    {
                        keyName: 'QUESTION_2',
                        text: 'QUESTIONS.CHAPTER_10.QUESTION_2',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_10.SECTION_2',
                questions: [
                    {
                        keyName: 'QUESTION_3',
                        text: 'QUESTIONS.CHAPTER_10.QUESTION_3',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_10.SECTION_3',
                questions: [
                    {
                        keyName: 'QUESTION_4',
                        text: 'QUESTIONS.CHAPTER_10.QUESTION_4',
                    },
                ],
            },
            {
                section: 'SECTIONS.CHAPTER_10.SECTION_4',
                questions: [
                    {
                        keyName: 'QUESTION_5',
                        text: 'QUESTIONS.CHAPTER_10.QUESTION_5',
                    },
                ],
            },
        ],
    },
    {
        chapter: 'CHAPTERS.FEEDBACK',
        chapterKeyName: 'FEEDBACK',
        block: [
            {
                questions: [
                    {
                        keyName: 'QUESTION_1',
                        text: 'QUESTIONS.FEEDBACK.QUESTION_1',
                    },
                ],
            },
            {
                questions: [
                    {
                        keyName: 'QUESTION_2',
                        text: 'QUESTIONS.FEEDBACK.QUESTION_2',
                    },
                ],
            },
            {
                questions: [
                    {
                        keyName: 'QUESTION_3',
                        text: 'QUESTIONS.FEEDBACK.QUESTION_3',
                    },
                ],
            },
        ],
    },
];
