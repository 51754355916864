import { createSlice } from '@reduxjs/toolkit';
import { IGroup, IUser } from '../../../interfaces';

type authProps = {
    status: 'no-authenticated' | 'authenticated' | 'checking';
    loading: boolean;
    user: IUser | null;
    group: IGroup | null;
    token: string | null;
    emailExist: boolean;
    error: {
        status: number,
        error: string,
    } | null;
}

const _initialState: authProps = {
    status: 'checking',
    loading: false,
    user: null,
    group: null,
    token: null,
    emailExist: false,
    error: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: _initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },

        login: (state, { payload }) => {
            state.user = payload.user;
            state.group = payload.studyGroup;
            state.status = 'authenticated';
            state.token = payload.token;
            state.loading = false;
        },

        logout: (state) => {
            state.user = null;
            state.group = null;
            state.token = null;
            state.loading = false;
            state.status = 'no-authenticated';
            state.emailExist = false;
        },

        setEmailExist: (state, { payload }) => {
            state.emailExist = payload;
        },

        setError: (state, { payload }) => {
            state.error = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setLoading,
    setEmailExist,
    login,
    logout,
    setError,
} = authSlice.actions;
