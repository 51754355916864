import { Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { BsArrowLeft } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/Forms/LoginForm';
import ContentWrapper from '../widgets/ContentWrapper';

const { Text } = Typography;

const Login = () => {
    const { t } = useTranslation('dashboard', { keyPrefix: 'LOGIN' });
    const navigate = useNavigate();

    return (
        <ContentWrapper title={t('WELCOME_TO_STUDY_GROUPS')}>
            <FaUsers color="#86C3FF" size={120} className="container__inner--icon-main" />
            <Text className="container__inner--text-help">
                <Trans
                    t={t}
                    i18nKey="HELP"
                    components={{
                        b: <b style={{ color: '#86C3FF' }} onClick={() => navigate('/groups/onboarding')} />,
                    }}
                />
            </Text>
            <div className="container__inner--forms">
                <LoginForm />
            </div>

            <Text
                onClick={() => navigate('/')}
                className="steper__inner--back"
            >
                <BsArrowLeft size={16} color="#86C3FF" />
                {t('EXIT')}
            </Text>
        </ContentWrapper>
    );
};

export default Login;
