import {
    configureStore, ThunkAction, Action,
} from '@reduxjs/toolkit';
import { authSlice } from './slices';

export const makeStore = () => configureStore({
    reducer: {
        auth: authSlice.reducer,
        // Add your reducers here
    },
    // Add your middleware here
});

const store = makeStore();

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType, RootState, unknown, Action<string>
>

export default store;
