/* global React JSX */
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

interface Props {
    children: JSX.Element;
}

const PublicRoute = ({ children }: Props) => {
    const { user } = useSelector((state: any) => state.auth);

    if (user?._id) {
        return <Navigate to="/groups/dashboard/home" />;
    }

    return children;
};

export default PublicRoute;
