import CountryCode from './CountryCode';
import Mobile from './Mobile';

interface Props {
    countryCodeName: string;
    mobileName: string;
}

const PhoneNumber = ({ countryCodeName, mobileName }: Props) => (
    <div className="phoneNumber">
        <div className="countryCode">
            <CountryCode name={countryCodeName} />
        </div>
        <div className="mobile">
            <Mobile name={mobileName} />
        </div>
    </div>
);

export default PhoneNumber;
