import cs from 'classnames';
import {
    Row, Col, Typography, Space, Button, Image,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IMAGES from '../../constants/images';

const { Title, Text, Paragraph } = Typography;

const Portrait = () => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME' });
    const VISEU_BUYER_BOOK = 'https://www.editoraviseu.com.br/magnifico-reitor-magnifico-prod.html?___SID=U';

    const [active, isActive] = useState<boolean>(false);

    return (
        <div className="home__section home__section--portrait">
            <Row gutter={[24, 32]} justify="space-around" align="middle">
                <Col xs={24} xl={14} xxl={8}>
                    <Space direction="vertical" size={24}>
                        <Title level={1}>
                            {t('PORTRAIT.BOOK')}
                        </Title>

                        <div className="home__section--author">
                            <Title level={2} className="home__section--author-title">
                                {t('PORTRAIT.BOOK_TITLE')}
                            </Title>

                            <Text className="home__section--author-name">
                                {t('PORTRAIT.AUTHOR')}
                                :
                                {' '}
                                <b>{t('PORTRAIT.BOOK_AUTHOR')}</b>
                            </Text>
                        </div>

                        <Space direction="vertical" size={8}>
                            <Paragraph
                                className={cs('home__section--author-description', { active })}
                            >
                                {t('PORTRAIT.BOOK_DESCRIPTION')}
                            </Paragraph>

                            <Text
                                className="home__section--more"
                                onClick={() => isActive(!active)}
                            >
                                {t(!active ? 'PORTRAIT.READ_MORE' : 'PORTRAIT.READ_LESS')}
                            </Text>
                        </Space>

                        <Button
                            type="primary"
                            onClick={() => window.open(VISEU_BUYER_BOOK, '_blank')}
                            className="home__section--author-button"
                        >
                            {t('PORTRAIT.BOOK_BUTTON')}
                        </Button>
                    </Space>
                </Col>
                <Col xs={24} xl={10} xxl={8}>
                    <Image
                        src={IMAGES.book}
                        preview={false}
                        className="home__section--portrait-image"
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Portrait;
