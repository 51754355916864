import { createContext, useState } from 'react';
import answersAPI from '../../../api/answers.api';
import usersAPI from '../../../api/users.api';
import { QUESTIONS } from '../../../constants/questions.constant';
import { IDashboardContext, IQuestions } from '../../../interfaces';
import { IAnswer } from '../../../interfaces/answer.interface';
import { QuestionMatchAnswers } from '../../../utils/questions.util';

interface Props {
    children: React.ReactNode;
}

const initialContext = {
    loading: false,
    valueEditor: '',
    currentChapter: 'RECTORY_CHALLENGE',
    allAnswers: [],
    questions: QUESTIONS,
    questionSelected: null,
    fetchAnswers: () => {},
    fetchUser: () => {},
    setValueEditor: () => {},
    setAllAnswers: () => {},
    setQuestionSelected: () => {},
    setCurrentChapter: () => {},
    setQuestions: () => {},
};

export const DashboardContext = createContext<IDashboardContext>(initialContext);

export const DashboardProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [currentChapter, setCurrentChapter] = useState<string>(initialContext.currentChapter);
    const [allAnswers, setAllAnswers] = useState<IAnswer[]>(initialContext.allAnswers);
    const [questions, setQuestions] = useState<IQuestions[]>(initialContext.questions);
    const [lastUpdate, setLastUpdate] = useState<any>();
    const [valueEditor, setValueEditor] = useState<string>('');
    const [questionSelected, setQuestionSelected] = useState<any>();

    const fetchUser = (userID: string) => {
        if (userID) {
            usersAPI.getUser(lastUpdate.userID)
                .then((data) => console.log({ data }))
                .catch((err) => console.log(err));
        }
    };

    const fetchAnswers = () => {
        setLoading(true);
        answersAPI.getAnswers()
            .then(({ data: { answers } }) => {
                const questionsMatched = QuestionMatchAnswers(answers, questions);
                setQuestions(questionsMatched);
                setAllAnswers(answers);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    return (
        <DashboardContext.Provider
            value={{
                loading,
                valueEditor,
                allAnswers,
                currentChapter,
                questions,
                questionSelected,
                fetchAnswers,
                fetchUser,
                setValueEditor,
                setAllAnswers,
                setQuestionSelected,
                setCurrentChapter,
                setQuestions,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};
