import { Typography, List } from 'antd';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RiFileEditFill } from 'react-icons/ri';
import { FiMoreHorizontal } from 'react-icons/fi';
import { AiOutlineLoading } from 'react-icons/ai';
import { IChapter } from '../../interfaces';
import { changeToRoman } from '../../utils/chapters.util';
import { CommunityContext } from './context/communityContext';

interface Props {
    chapter: IChapter;
    index: number;
    t: (value: string) => string;
}

const { Title, Text } = Typography;

const Chapter = ({ chapter, index, t }: Props) => {
    const { t: td } = useTranslation('dashboard', { keyPrefix: 'DASHBOARD' });

    const title = t(`SUMMARY.${chapter.title}`);
    const indexChapter = `${t('SUMMARY.CHAPTER')} ${index}`;

    const {
        loadingAnswers,
        publicQuestionAnswers,
        chapterSelected,
        setChapterSelected,
    } = useContext(CommunityContext);
    const { chapter: chapterName, section, block: blocks } = publicQuestionAnswers;

    const isFirst = chapterName === 'RECTORY_CHALLENGE';

    return (
        <div
            className="community__chapter"
            onClick={() => setChapterSelected(chapter.keyName)}
        >
            <Title level={4}>
                {
                    index === 0 ? title : `${indexChapter} - ${title}`
                }
            </Title>
            {
                chapter?.description && (
                    <Text className="community__text-description">
                        {t(`SUMMARY.${chapter?.description}`)}
                    </Text>
                )
            }
            <Text className="community__text-total">
                <RiFileEditFill color="#86C3FF" size={20} />
                {`${chapter?.totalAnswers} ${t(chapter?.totalAnswers === 1 ? 'SUMMARY.ANSWER' : 'SUMMARY.ANSWERS')}`}
            </Text>

            {
                loadingAnswers ? (
                    chapter.keyName === chapterSelected && (
                        <div className="questions__answers-empty">
                            <AiOutlineLoading size={32} color="#86C3FF" className="rotate" />
                            <Text className="question__answers-text">
                                {td('GETTING_ANSWERS')}
                            </Text>
                        </div>
                    )
                ) : (
                    <div className="community__questions">
                        {
                            chapter.keyName === chapterSelected && (
                                <div className="questions" style={{ paddingRight: 0 }}>
                                    <Title level={4} className="questions__section">
                                        {
                                            section
                                                ? td(section)
                                                : td(chapterName)
                                        }
                                    </Title>

                                    <div className="questions__list">
                                        {
                                            blocks?.map((itemBlock, indexBlock) => (
                                                <div className="questions__item" key={indexBlock}>
                                                    <Text className="questions__item-section">
                                                        { itemBlock.section && (td(itemBlock.section)) }
                                                    </Text>
                                                    {
                                                        itemBlock?.questions?.map((question, index) => (
                                                            <div key={question.keyName} className="question" style={{ paddingRight: 0 }}>
                                                                {
                                                                    isFirst ? (
                                                                        <Text className="question__number">
                                                                            {`${changeToRoman(index + 1)}. ${td(question.text)}`}
                                                                        </Text>
                                                                    ) : (
                                                                        <Text className="question__number">
                                                                            {td(question.text)}
                                                                        </Text>
                                                                    )
                                                                }
                                                                {
                                                                    question?.answers && question?.answers?.length > 0 ? (
                                                                        <div className="questions__answers-container">
                                                                            {
                                                                                question?.answers?.map((answerData, index) => (
                                                                                    <List.Item.Meta
                                                                                        style={{
                                                                                            marginBottom: 16,
                                                                                            padding: 12,
                                                                                            borderRadius: 8,
                                                                                            backgroundColor: '#1D2433',
                                                                                        }}
                                                                                        key={answerData?._id}
                                                                                        title={(
                                                                                            <Text className="questions__answers-title">
                                                                                                <Trans
                                                                                                    t={td}
                                                                                                    i18nKey="ANSWER_FROM_GROUP"
                                                                                                    values={{ groupName: answerData.studyGroupID ? answerData.studyGroupID?.name : '' }}
                                                                                                />
                                                                                            </Text>
                                                                                        )}
                                                                                        description={(
                                                                                            <Text key={answerData?._id} className="question__answers-text">
                                                                                                <div
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: answerData.content,
                                                                                                    }}
                                                                                                />
                                                                                            </Text>
                                                                                        )}
                                                                                    />
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    ) : (
                                                                        <div className="questions__answers-empty">
                                                                            <FiMoreHorizontal size={24} color="#86C3FF" />
                                                                            <Text className="question__answers-text">
                                                                                {td('NO_ANSWERS')}
                                                                            </Text>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }

        </div>
    );
};

export default Chapter;
