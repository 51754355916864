import {
    Form, Input, Button, Space, Typography, notification, Row, Col,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createFeedback } from '../../api/feedback.api';
import Country from '../../widgets/Country';
import InstituteType from '../../widgets/InstituteType';
import UploadImage from '../../widgets/UploadImage';

interface Props {
    handleSendFeedback: () => void;
}

const { Title, Text } = Typography;
const { TextArea } = Input;

const FeedbackForm = ({ handleSendFeedback }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const onSubmit = (values: any) => {
        setLoading(true);
        const payload = {
            ...values,
            approved: false,
            rate: 5,
        };

        createFeedback(payload)
            .then(() => {
                form.resetFields();
                notification.success({
                    message: tf('RESPONSES.FEEDBACK_SUCCESS'),
                });
                handleSendFeedback();
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
        >
            <Space direction="vertical" size={32} className="full__size">
                <Space direction="vertical" size={8} className="full__size">
                    <Title level={3}>
                        {tf('HEADER.FEEDBACK')}
                    </Title>
                    <Text className="home__section--feedback-modal-description">
                        {tf('HEADER.FEEDBACK_DESCRIPTION')}
                    </Text>
                </Space>
                <Space direction="vertical" className="full__size">
                    <Row justify="space-between" align="top" gutter={[24, 40]}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={tf('INPUTS.NAME')}
                                name="name"
                                rules={RULES}
                            >
                                <Input placeholder={tf('INPUTS.NAME_PLACEHOLDER')} />
                            </Form.Item>

                            <Form.Item
                                label={tf('INPUTS.LAST_NAME')}
                                name="lastName"
                                rules={RULES}
                            >
                                <Input placeholder={tf('INPUTS.LAST_NAME_PLACEHOLDER')} />
                            </Form.Item>

                            <Country name="country" />

                            <Form.Item
                                label={tf('INPUTS.IMAGE')}
                                name="image"
                            >
                                <UploadImage t={tf} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={tf('INPUTS.ACADEMIC.JOB_POSITION')}
                                name="jobPosition"
                                rules={RULES}
                            >
                                <Input placeholder={tf('INPUTS.ACADEMIC.JOB_POSITION_PLACEHOLDER')} />
                            </Form.Item>

                            <Form.Item
                                label={tf('INPUTS.ACADEMIC.INSTITUTE')}
                                name="institute"
                                rules={RULES}
                            >
                                <Input placeholder={tf('INPUTS.ACADEMIC.INSTITUTE_PLACEHOLDER')} />
                            </Form.Item>

                            <InstituteType />

                            <Form.Item
                                label={tf('INPUTS.FEEDBACK')}
                                name="comment"
                                rules={RULES}
                            >
                                <TextArea placeholder={tf('INPUTS.FEEDBACK_PLACEHOLDER')} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Space>

                <Button
                    block
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                >
                    {tf('ACTIONS.SET_FEEDBACK')}
                </Button>
            </Space>

        </Form>
    );
};

export default FeedbackForm;
