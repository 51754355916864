import ReactDOM from 'react-dom/client';

import { Suspense } from 'react';
import { Provider } from 'react-redux';

import './plugins';
import App from './App';
import store from './store';
import Loading from './screens/Loading';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
    <Provider store={store}>
        <Suspense fallback={<Loading />}>
            <App />
        </Suspense>
    </Provider>,
);
