import { Avatar, Typography } from 'antd';
import {
    AiFillFacebook,
    AiFillTwitterSquare,
    AiFillLinkedin,
    AiFillInstagram,
    AiFillYoutube,
    AiTwotoneMail,
} from 'react-icons/ai';
import IMAGES from '../../constants/images';
import { NAMES, SOCIAL_MEDIA_LIST } from '../../constants/media';
import { ISocialMedia } from '../../interfaces';

const { Title } = Typography;

const Footer = () => {

    const SocialIcons = {
        [NAMES.FACEBOOK]: <AiFillFacebook />,
        [NAMES.TWITTER]: <AiFillTwitterSquare />,
        [NAMES.LINKEDIN]: <AiFillLinkedin />,
        [NAMES.INSTAGRAM]: <AiFillInstagram />,
        [NAMES.YOUTUBE]: <AiFillYoutube />,
        [NAMES.EMAIL]: <AiTwotoneMail />,
    };

    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__container--left">
                    <div className="footer__container--left-logo">
                        <Avatar
                            className="home__section--author-data--brands-avatar"
                            src={IMAGES.logo.rectoral}
                            shape="square"
                        />
                    </div>
                    <div className="footer__container--left-media">
                        {
                            SOCIAL_MEDIA_LIST.map((social: ISocialMedia) => (
                                <a
                                    className="footer__container--left-media-icon"
                                    key={social.keyName}
                                    href={social.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {SocialIcons[social.keyName]}
                                </a>
                            ))
                        }
                    </div>

                </div>
                <Title level={4}>
                    © 2023 Copyright. All rights reserved.
                </Title>
            </div>
        </footer>
    );
};

export default Footer;
