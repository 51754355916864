import { Avatar, Row } from 'antd';
import cs from 'classnames';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import IMAGES from '../../constants/images';

interface Items {
    key: number;
    name: string;
    path: string;
    active: boolean;
}

interface Props {
    isClosed: boolean;
    toogleClosed: () => void;
    items: Items[];
    itemActive: number;
}

const Mobile = ({
    isClosed, toogleClosed, items, itemActive,
}: Props) => {
    const navigate = useNavigate();

    return (
        <div className={cs('navbar__mobile', { active: isClosed })}>
            <Row justify="space-between" align="middle" className="full__width">
                <Avatar
                    size={84}
                    shape="square"
                    src={IMAGES.logo.book}
                    className="navbar__mobile--logo"
                    onClick={() => navigate('/')}
                />

                <MdClose
                    size={32}
                    color="#FFF"
                    onClick={() => toogleClosed()}
                    className="navbar__mobile--close"
                />
            </Row>

            <div className="navbar__mobile--menu">
                {
                    items.map((item, index) => (
                        <div
                            key={index}
                            className={cs('navbar__mobile--menu-item', { active: item.key === itemActive })}
                            onClick={() => {
                                navigate(item.path);
                                toogleClosed();
                            }}
                        >
                            {item.name}
                            <div className="navbar__mobile--menu-item-line line" />
                        </div>
                    ))
                }

            </div>
        </div>
    );
};

export default Mobile;
