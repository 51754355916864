import IMAGES from './images';

const AVAILABLE_PRINTED = [
    {
        name: 'Viseu',
        logo: IMAGES.available.printed.viseu,
        url: 'https://www.editoraviseu.com.br/magnifico-reitor-magnifico-prod.html?___SID=U',
    },
    {
        name: 'Amazon',
        logo: IMAGES.available.printed.amazon,
        url: 'https://www.amazon.com.br/Magn%C3%ADfico-magn%C3%ADfico-Luciano-Rodrigues-Marcelino/dp/6525409640/ref=sr_1_1?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=DEOGG25DT8RC&keywords=9786525409641&qid=1676480515&sprefix=9786525409641%2Caps%2C319&sr=8-1',
    },
    {
        name: 'Americanas',
        logo: IMAGES.available.printed.americanas,
        url: 'https://www.americanas.com.br/produto/7207423537?pfm_carac=livro-magnifico-reitor-magnifico&pfm_index=1&pfm_page=search&pfm_pos=grid&pfm_type=search_page&offerId=63ea4ada401db3b86bc96e1b',
    },
    {
        name: 'Shoptime',
        logo: IMAGES.available.printed.shoptime,
        url: 'https://www.shoptime.com.br/produto/7207423537?pfm_carac=7207423537&pfm_index=1&pfm_page=search&pfm_pos=grid&pfm_type=search_page&offerId=63ea4ada643f314f7f042fc2',
    },
    {
        name: 'Submarino',
        logo: IMAGES.available.printed.submarino,
        url: 'https://www.submarino.com.br/produto/7207423537?pfm_carac=7207423537&pfm_index=1&pfm_page=search&pfm_pos=grid&pfm_type=search_page&offerId=63ea4adaeca21af261c1f33a',
    },
    {
        name: 'Magazine Luiza',
        logo: IMAGES.available.printed.magalu,
        url: 'https://www.magazineluiza.com.br/magnifico-reitor-magnifico/p/gbcbg771f7/li/otli/',
    },
];

const AVAILABLE_DIGITAL = [
    {
        name: 'Amazon',
        logo: IMAGES.available.digital.amazon,
        url: 'https://www.amazon.com.br/Magn%C3%ADfico-magn%C3%ADfico-Luciano-Rodrigues-Marcelino/dp/6525409640/ref=sr_1_1?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=DEOGG25DT8RC&keywords=9786525409641&qid=1676480515&sprefix=9786525409641%2Caps%2C319&sr=8-1',
    },
    {
        name: 'Apple',
        logo: IMAGES.available.digital.apple,
        url: 'https://books.apple.com/br/book/id6445398236',
    },
    {
        name: 'Barnes & Noble',
        logo: IMAGES.available.digital.barnes,
        url: 'https://www.barnesandnoble.com/w/magn-fico-reitor-magn-fico-luciano-rodrigues-marcelino/1142940410?ean=9786525409658',
    },
    {
        name: 'Google Play',
        logo: IMAGES.available.digital.google_play,
        url: 'https://play.google.com/store/books/details?id=tiqnEAAAQBAJ&PAffiliateID=1100lecwI&gl=br',
    },
    {
        name: 'Kobo',
        logo: IMAGES.available.digital.rakuten,
        url: 'https://www.kobo.com/br/pt/ebook/magnifico-reitor-magnifico',
    },
    {
        name: 'Livraria',
        logo: IMAGES.available.digital.livraria,
        url: 'https://www3.livrariacultura.com.br/magnifico-reitor-magnifico-892903478/p',
    },
    {
        name: 'Wook',
        logo: IMAGES.available.digital.wook,
        url: 'https://www.wook.pt/ebook/magnifico-reitor-magnifico-luciano-rodrigues-marcelino/28066809',
    },
];

export { AVAILABLE_PRINTED, AVAILABLE_DIGITAL };
