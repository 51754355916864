import { Typography } from 'antd';
import { ReactNode } from 'react';

const { Title } = Typography;

interface Props {
    title: string;
    children: ReactNode;
}

const ContentWrapper = ({ title, children }: Props) => (
    <div className="full__size screen__login">
        <div className="container">
            <Title level={1} style={{ textAlign: 'center' }}>
                {title}
            </Title>

            <div className="container__inner">
                <div className="container__inner--data">
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export default ContentWrapper;
