import axios from 'axios';
import { BOOK_API } from '../config';

const getMetrics = () => {
    const url = `${BOOK_API}/metrics`;
    const response = axios.get(url);
    return response;
};

export default getMetrics;
