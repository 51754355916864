import axios from 'axios';
import { BOOK_API } from '../config';
import { IResponseList } from '../interfaces/response.interface';
import { IReview } from '../interfaces';

const getReviews = (query: any): Promise<IResponseList<IReview[]>> => {
    const {
        limit, page, search, country,
    } = query;

    const params = new URLSearchParams();

    params.append('limit', limit);
    params.append('page', page);

    if (search && search !== '') {
        params.append('search', search);
    }

    if (country && country !== '') {
        params.append('country', country);
    }

    const url = `${BOOK_API}/reviews`;
    const response = axios.get(url, { params }) as Promise<IResponseList<IReview[]>>;
    return response;
};

export default getReviews;
