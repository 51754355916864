import { Button, message, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckDouble } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react18-input-otp';
import ContentWrapper from '../widgets/ContentWrapper';
import groupsAPI from '../api/groups.api';

const { Text } = Typography;

const ValidateOTP = () => {
    const { t } = useTranslation('dashboard', { keyPrefix: 'VALIDATE_GROUP' });
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const location = useLocation();
    const navigate = useNavigate();

    const [codeOTP, setCodeOTP] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [ownerID, setOwnerID] = useState<string>();
    const [validated, setValidated] = useState<boolean>(false);

    const handleChange = (enteredOtp: string) => setCodeOTP(enteredOtp);

    const handleValidate = () => {
        setLoading(true);

        if (!codeOTP) {
            message.warning(tf('RESPONSES.OTP_REQUIRED'));
            setLoading(false);
            return;
        }

        if (codeOTP.length < 6) {
            message.warning(tf('RESPONSES.OTP_LENGTH'));
            setLoading(false);
            return;
        }

        const params = {
            ownerID,
            codeOTP,
        };

        groupsAPI.validateStudyGroup(params)
            .then(() => {
                message.success(tf('RESPONSES.STUDYGROUP_VERIFIED'));
                setValidated(true);
            })
            .catch(() => {
                message.error(tf('RESPONSES.STUDYGROUP_NOT_VERIFIED'));
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        const ownerID = location.search.split('=')[1];
        setOwnerID(ownerID);
        if (!ownerID) {
            message.warning(tf('RESPONSES.NO_OWNER_DATA'));
        }
    }, []);

    return (
        !validated ? (
            <ContentWrapper title={t('HEADER')}>
                <FaCheckDouble color="#86C3FF" size={120} className="container__inner--icon-main" />
                <Text className="container__inner--text-help">
                    {t('DESCRIPTION')}
                </Text>
                <OtpInput
                    value={codeOTP}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span>-</span>}
                    className="otp-input"
                    containerStyle="otp-container"
                    inputStyle="otp-input"
                />
                <div className="container__inner--forms">
                    <Button
                        block
                        type="primary"
                        size="large"
                        loading={loading}
                        disabled={!ownerID}
                        className="home__section--groups-button"
                        onClick={handleValidate}
                    >
                        {t('VALIDATE') }
                    </Button>
                </div>
            </ContentWrapper>
        ) : (
            <ContentWrapper title={t('VALIDATED_GROUP')}>
                <FaCheckDouble color="#05c988" size={120} className="container__inner--icon-main" />
                <Text className="container__inner--text-help">
                    {t('SENDED_EMAIL')}
                </Text>
                <div className="container__inner--forms">
                    <Button
                        block
                        type="primary"
                        size="large"
                        className="home__section--groups-button"
                        onClick={() => navigate('/groups/login', { replace: true })}
                    >
                        {t('LOGIN') }
                    </Button>
                </div>
            </ContentWrapper>
        )
    );
};

export default ValidateOTP;
