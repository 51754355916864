import usersAPI from '../api/users.api';

const validateExistMembers = (async (group: any) => {
    let { secretary, leadership, members } = group;
    const secretaryEmail = secretary.email;
    const leadershipEmail = leadership.email;
    const { data: secretaryData } = await usersAPI.getUsers({ search: secretaryEmail });
    const { data: leadershipData } = await usersAPI.getUsers({ search: leadershipEmail });

    const secretaryExist = secretaryData[0];
    const leadershipExist = leadershipData[0];

    if (!secretaryExist) {
        const { data } = await usersAPI.createUser(secretary);
        secretary = { ...secretary, ...data };
    } else {
        secretary = secretaryExist;
    }

    if (!leadershipExist) {
        const { data } = await usersAPI.createUser(leadership);
        leadership = { ...leadership, ...data };
    } else {
        leadership = leadershipExist;
    }

    members = members.push(secretary._id);

    return group;
});

const formatGroupData = ((group: any) => {
    const formatedData = {
        secretary: {
            name: group.secretaryName,
            lastName: group.secretaryLastName,
            email: group.secretaryEmail,
            mobile: group.secretaryMobile,
            countryCode: group.secretaryCountryCode,
            fullMobile: `${group.secretaryCountryCode} ${group.secretaryMobile}`,
            country: group.secretaryCountry,
            origin: group.secretaryOrigin,
            role: group.secretaryRole,
            password: group.secretaryPassword,
        },
        leadership: {
            name: group.leadershipName,
            lastName: group.leadershipLastName,
            email: group.leadershipEmail,
            mobile: group.leadershipMobile,
            countryCode: group.leadershipCountryCode,
            fullMobile: `${group.leadershipCountryCode} ${group.leadershipMobile}`,
            country: group.leadershipCountry,
            origin: group.leadershipOrigin,
            role: group.leadershipRole,
            password: group.leadershipPassword,
        },
        members: group.members?.map((member: any) => member._id),
        nameGroup: group.nameGroup,
    };

    return formatedData;
});

export {
    validateExistMembers,
    formatGroupData,
};
