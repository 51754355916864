import {
    Row, Col, Space, Typography, Button,
} from 'antd';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import getMetrics from '../../api/metrics.api';

interface ISteps {
    title: string;
    description: string;
    label: string;
}

const { Title, Text, Paragraph } = Typography;

const InvitationGroups = () => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME' });
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [groups, setGroups] = useState<number>(0);
    const [students, setStudents] = useState<number>(0);

    const STEPS: ISteps[] = [
        {
            title: t('INVITATION_GROUPS.STEPS.STEP_1.TITLE'),
            description: t('INVITATION_GROUPS.STEPS.STEP_1.DESCRIPTION'),
            label: t('INVITATION_GROUPS.STEPS.STEP_1.STEP'),
        },
        {
            title: t('INVITATION_GROUPS.STEPS.STEP_2.TITLE'),
            description: t('INVITATION_GROUPS.STEPS.STEP_2.DESCRIPTION'),
            label: t('INVITATION_GROUPS.STEPS.STEP_2.STEP'),
        },
        {
            title: t('INVITATION_GROUPS.STEPS.STEP_3.TITLE'),
            description: t('INVITATION_GROUPS.STEPS.STEP_3.DESCRIPTION'),
            label: t('INVITATION_GROUPS.STEPS.STEP_3.STEP'),
        },
    ];

    const fetchMetrics = async () => {
        setLoading(true);
        getMetrics()
            .then(({ groups, students }: any) => {
                setGroups(groups);
                setStudents(students);
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
        setLoading(false);
    };

    useEffect(() => {
        fetchMetrics();
    }, []);

    return (
        <div className="home__section home__section--groups">
            <div className="home__section--groups-line top" />
            <div className="home__section--groups-line bottom" />

            <Space direction="vertical" size={32}>
                <Title level={1} className="home__section--groups-header">
                    {t('INVITATION_GROUPS.JOINED_STUDY_GROUPS')}
                </Title>
                <Row gutter={[48, 32]} justify="center">
                    {
                        STEPS.map((step, index) => (
                            <Col xs={24} xl={7} key={index}>
                                <div className="home__section--groups-step">
                                    <Title level={2} className="home__section--groups-step-title">
                                        {step.title}
                                    </Title>
                                    <Text className="home__section--groups-step-number">
                                        {step.label}
                                    </Text>
                                    <Paragraph className="home__section--groups-step-description">
                                        {step.description}
                                    </Paragraph>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
                <Button
                    type="primary"
                    size="large"
                    className="home__section--groups-button"
                    onClick={() => navigate('/groups/dashboard/home', { replace: true })}
                >
                    {t('INVITATION_GROUPS.GET_STARTING') }
                </Button>
            </Space>

            <div className="home__section--divider" />

            <Row justify="center" align="middle" style={{ width: '80%' }}>
                <Col span={24} className="flex-column center-column-center">
                    <Title level={2} className="home__section--count-header">
                        {t('INVITATION_GROUPS.JOIN_OUR_STUDY_GROUPS')}
                    </Title>
                    <Title level={2} className="home__section--count-subheader">
                        <Trans
                            t={t}
                            i18nKey="INVITATION_GROUPS.COUNT_DESCRIPTION"
                            components={{
                                b: <b style={{ color: '#86C3FF', fontSize: 38 }} />,
                            }}
                            values={{
                                groups,
                                students,
                            }}
                        />
                    </Title>
                </Col>
                <Col span={24} className="flex-column center-column-center">
                    <Title
                        level={3}
                        className="home__section--count-link"
                        onClick={() => navigate('/community', { replace: true })}
                    >
                        {t('INVITATION_GROUPS.GO_TO_PUBLIC_RESPONSES')}
                    </Title>
                </Col>
            </Row>
        </div>
    );
};

export default InvitationGroups;
