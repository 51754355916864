import {
    Col, Row, Typography, notification,
} from 'antd';
import { useEffect, useState } from 'react';
import { IEvent } from '../../interfaces';
import EventCard from '../../widgets/EventCard';
import Calendar from './Calendar';
import getEvents from '../../api/events.api';

interface Props {
    t: (key: string) => string;
}

const { Title, Text } = Typography;

const Portrait = ({ t }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [events, setEvents] = useState<IEvent[]>([]);

    const fetchEvents = () => {
        setLoading(true);
        getEvents()
            .then((response: any) => {
                setEvents(response);
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    return (
        <div className="home__section home__section--portrait">
            <Row gutter={[24, 32]} justify="space-around" align="middle">
                <Col xs={24} xl={12}>
                    <Title level={1} className="home__section--schedule-calendar-header">
                        {`${t('HEADER')} (${events?.length || 0})`}
                    </Title>

                    <Text className="home__section--schedule-calendar-description">
                        {t('DESCRIPTION')}
                    </Text>

                    <div className="home__section--schedule-calendar--events">
                        {
                            events?.map((event: IEvent) => (
                                <EventCard event={event} t={t} />
                            ))
                        }
                    </div>
                </Col>
                <Col xs={24} xl={12}>
                    <Calendar />
                </Col>
            </Row>
        </div>
    );
};

export default Portrait;
