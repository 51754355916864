import {
    Avatar, Col, notification, Row, Space, Typography, Button,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFeedback } from '../../api/feedback.api';
import { IFeedback } from '../../interfaces';
import { COUNTRIES } from '../../utils/countries.util';
import FeedbackCard from '../../widgets/FeedbackCard';
import ModalWrapper from '../../widgets/ModalWrapper';
import FeedbackForm from '../Forms/FeedbackForm';

const { Title, Text } = Typography;

const Feedback = () => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME' });

    const [loading, setLoading] = useState<boolean>(false);
    const [feedbacks, setFeedbacks] = useState<IFeedback[]>([]);
    const [feedbackSelected, setFeedbackSelected] = useState<IFeedback | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleFeedback = (feedback: IFeedback) => setFeedbackSelected(feedback);

    const findImage = (country: string) => COUNTRIES.filter((item: any) => item.code === country)[0].flag;

    const fetchFeedbacks = () => {
        setLoading(true);
        getFeedback()
            .then((response: any) => {
                setFeedbacks(response);
                handleFeedback(response[0]);
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    const handleSendFeedback = () => {
        setOpen(false);
        fetchFeedbacks();
    };

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    return (
        <div className="home__section home__section--feedback" id="feedback">
            <ModalWrapper
                visible={open}
                onCancel={() => {
                    setOpen(false);
                    fetchFeedbacks();
                }}
            >
                <FeedbackForm handleSendFeedback={handleSendFeedback} />
            </ModalWrapper>
            <Space direction="vertical" size={48}>
                <Title level={1}>
                    {t('FEEDBACK.HEADER')}
                </Title>
                <Row gutter={[24, 32]} justify="space-between" align="top">
                    <Col xs={24} xl={12}>
                        <div className="home__section--feedback-cards">
                            {
                                feedbacks.map((item: any, index: number) => (
                                    <FeedbackCard feedback={item} onClick={handleFeedback} />
                                ))
                            }
                        </div>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Space className="full__size" direction="vertical" size={32}>
                            {
                                feedbackSelected && (
                                    <div className="home__section--feedback-select">
                                        <Avatar
                                            className="home__section--feedback-select-flag"
                                            src={findImage(feedbackSelected?.country)}
                                            size={160}
                                        />
                                        <Avatar
                                            size={64}
                                            src={feedbackSelected.image}
                                            className="home__section--feedback-select-avatar"
                                        />
                                        <Space
                                            size={4}
                                            direction="vertical"
                                            className="full__width"
                                            style={{ display: 'flex', alignItems: 'center' }}
                                        >
                                            <Title level={4} className="home__section--feedback-select-name">
                                                {`${feedbackSelected.name} ${feedbackSelected.lastName}`}
                                            </Title>
                                            <Text className="home__section--feedback-select-comment">
                                                {feedbackSelected.jobPosition}
                                            </Text>
                                        </Space>
                                        <Text className="home__section--feedback-select-comment">
                                            {feedbackSelected.comment}
                                        </Text>
                                    </div>
                                )
                            }

                            <Button
                                type="primary"
                                size="large"
                                className="home__section--groups-button"
                                onClick={() => setOpen(true)}
                            >
                                {t('FEEDBACK.I_WANT_TO')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Space>
        </div>
    );
};

export default Feedback;
