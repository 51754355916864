import {
    CreateGroup, InviteMembers, StartStudying,
} from '../components/Onboarding';
import { IStep } from '../interfaces';

type TSteps = IStep[];

export const STEPS_ONBOARDING: TSteps = [
    {
        stepName: 'STEPS.CREATE_GROUP',
        help: 'STEPS.CREATE_GROUP_HELP',
        tag: 'create-group',
        component: CreateGroup,
    },
    {
        stepName: 'STEPS.INVITE_MEMBERS',
        help: 'STEPS.INVITE_MEMBERS_HELP',
        tag: 'invite-members',
        component: InviteMembers,
    },
    {
        stepName: 'STEPS.START_STUDYING',
        help: 'STEPS.START_STUDYING_HELP',
        tag: 'start-studying',
        component: StartStudying,
    },
];
