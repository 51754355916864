import {
    Col, Row, Space, Tabs, Tooltip, Typography,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import { Trans } from 'react-i18next';
import { changeToRoman } from '../../utils/chapters.util';
import { DashboardContext } from './context/dashboardContext';
import Header from './Header';
import TextEditor from './TextEditor';
import { IQuestions } from '../../interfaces';
import ManageQuestions from './ManageQuestions';

const { Title, Text } = Typography;

const Content = ({ t }: { t: (value: string) => string }) => {
    const [items, setItems] = useState<any[]>([]);

    const {
        questions, questionSelected, fetchAnswers, setCurrentChapter, setQuestionSelected,
    } = useContext(DashboardContext);

    const handleChange = () => (key: string) => {
        setQuestionSelected(null);
        setCurrentChapter(key);
    };

    useEffect(() => {
        fetchAnswers();
    }, []);

    useEffect(() => {
        const items = questions.map((question: IQuestions, index: number) => ({
            key: question.chapterKeyName,
            label: (
                index === 0 || index === questions.length - 1
                    ? t(question.chapter)
                    : (
                        <Trans
                            t={t}
                            i18nKey="CHAPTER"
                            values={{ number: changeToRoman(index) }}
                        />
                    )
            ),
            children: <ManageQuestions t={t} />,
        }));
        setItems(items);
    }, []);

    return (
        <Row justify="space-between" align="top" gutter={[28, 36]}>
            <Col xs={24} lg={15} xl={16} xxl={14}>
                <Space direction="vertical" size={48}>
                    <Header t={t} />
                    <Tabs items={items} onChange={handleChange()} />
                </Space>
            </Col>
            <Col xs={24} lg={9} xl={8} xxl={10}>
                <Space direction="vertical" size={16}>
                    <Tooltip placement="right" title={t('EDITOR_TEXT_DESCRIPTION')}>
                        <Title level={3}>
                            {t('EDITOR_TEXT')}
                            <BiHelpCircle
                                color="#86C3FF"
                                size={20}
                                style={{ verticalAlign: 'middle', marginLeft: 8 }}
                            />
                        </Title>
                    </Tooltip>
                    <Space direction="vertical" size={32}>
                        {
                            questionSelected && (
                                <Space direction="vertical" size={8}>
                                    <Title level={4}>
                                        <Trans
                                            t={t}
                                            i18nKey="CHAPTER_TO_EDIT"
                                            values={{ chapter: t(questionSelected?.chapter?.name) }}
                                        />
                                    </Title>

                                    <Text className="questions__item-section">
                                        <Trans
                                            t={t}
                                            i18nKey="QUESTION_TO_EDIT"
                                            values={{ question: t(questionSelected?.question?.text) }}
                                        />
                                    </Text>
                                </Space>
                            )
                        }
                        <TextEditor t={t} />
                    </Space>
                </Space>
            </Col>
        </Row>
    );
};

export default Content;
