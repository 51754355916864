import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { AiOutlineGlobal } from 'react-icons/ai';
import { IoCaretDownSharp } from 'react-icons/io5';

const SwitchLanguage = () => {
    const { t: l, i18n } = useTranslation('home', { keyPrefix: 'HOME.LANGUAGES' });

    const changeLanguage = (value: string) => {
        i18n.changeLanguage(value);
    };

    return (
        <div>
            <AiOutlineGlobal />
            <Select
                value={i18n.resolvedLanguage}
                onChange={changeLanguage}
                suffixIcon={<IoCaretDownSharp color="#3F4D70" size={10} />}
            >
                <Select.Option value="es">
                    <div>
                        {l('ES')}
                    </div>
                </Select.Option>
                <Select.Option value="en">
                    <div>
                        {l('EN')}
                    </div>
                </Select.Option>
                <Select.Option value="pt">
                    <div>
                        {l('PT')}
                    </div>
                </Select.Option>
            </Select>
        </div>
    );
};

export default SwitchLanguage;
