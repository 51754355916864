import { Tag } from 'antd';
import { ILink } from '../../interfaces';

interface Props {
    links: ILink[];
}

const ExternalLinks = ({ links }: Props) => {
    if (links.length === 0) return null;
    return (
        <div className="home__section--experiences-links">
            {
                links.map((link: ILink) => (
                    <Tag key={link.keyName}>
                        <a href={link.value} target="_blank" rel="noopener noreferrer">
                            {link.name}
                        </a>
                    </Tag>
                ))
            }
        </div>
    );
};

export default ExternalLinks;
