import cs from 'classnames';
import moment from 'moment';
import {
    Button, Space, Tooltip, Typography,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { BiShow, BiHide } from 'react-icons/bi';
import { changeToRoman } from '../../utils/chapters.util';
import { DashboardContext } from './context/dashboardContext';
import { IQuestionData } from '../../interfaces';

interface Props {
    index: number;
    chapter: string;
    section: string;
    chapterKeyName: string;
    question: IQuestionData;
    t: (value: string) => string;
}

const { Text } = Typography;

const CardQuestion = ({
    index, chapter, section, chapterKeyName, question, t,
}: Props) => {
    const { keyName, text, answer } = question;
    const isFirst = chapterKeyName === 'RECTORY_CHALLENGE';
    const [isActive, setIsActive] = useState<boolean>(false);

    const { questionSelected, setQuestionSelected } = useContext(DashboardContext);

    const handleSelectQuestion = () => {
        setQuestionSelected({
            chapter: {
                keyName: chapterKeyName,
                name: chapter,
                section,
            },
            question,
        });
    };

    useEffect(() => {
        setIsActive(questionSelected?.question?.keyName === keyName && questionSelected?.chapter.keyName === chapterKeyName);
    }, [questionSelected]);

    return (
        <div className={cs('question', { active: isActive })}>
            <Space direction="horizontal" size={12}>
                {
                    isFirst ? (
                        <Text className="question__number">
                            {`${changeToRoman(index + 1)}. ${t(text)}`}
                        </Text>
                    ) : (
                        <Text className="question__number">
                            {t(text)}
                        </Text>
                    )
                }
                <Text className="question__text">
                    {
                        answer?.isComplete && (
                            <Tooltip title={t('ANSWERED')} placement="top">
                                <BsFillCheckCircleFill color="#86C3FF" size={20} />
                            </Tooltip>
                        )
                    }
                    {
                        answer?.isPublic ? (
                            <Tooltip title={t('PUBLIC')} placement="top">
                                <BiShow color="#86C3FF" size={24} />
                            </Tooltip>
                        ) : (
                            <Tooltip title={t('PRIVATE')} placement="top">
                                <BiHide color="#86C3FF" size={24} />
                            </Tooltip>
                        )
                    }
                </Text>
            </Space>

            {
                answer?.updates && answer?.updates?.length > 0 && (
                    <Text className="question__update">
                        {`${t('LAST_UPDATE')}: ${moment(answer.updates[answer.updates.length - 1].updatedAt).calendar()}`}
                    </Text>
                )
            }

            <div className="question__actions">
                <Button
                    className="question__actions-btn"
                    onClick={() => handleSelectQuestion()}
                >
                    {t(answer?.isComplete ? 'EDIT' : 'ANSWER')}
                </Button>
            </div>
        </div>
    );
};

export default CardQuestion;
