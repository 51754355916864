import {
    Button, PageHeader, Space, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { FaUsers } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Content } from '../components/Dashboard';
import { DashboardProvider } from '../components/Dashboard/context/dashboardContext';
import { logout } from '../store/slices/auth';

const { Title } = Typography;

const DashboardHome = () => {
    const { t } = useTranslation('dashboard', { keyPrefix: 'DASHBOARD' });
    const { group } = useSelector((state: any) => state.auth);

    const groupName = `${group?.name} - ${group?.code}`;
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    return (
        <DashboardProvider>
            <div className="dashboard full__size">
                <PageHeader
                    title={(
                        <Space direction="horizontal" size={12}>
                            <FaUsers color="#86C3FF" size={40} />
                            <Title level={2}>
                                {groupName}
                            </Title>
                        </Space>
                    )}
                    extra={(
                        <Space direction="horizontal" size={8}>
                            <Button
                                type="primary"
                                className="question__actions-btn"
                                onClick={() => navigate('/groups/dashboard/community', { replace: true })}
                            >
                                {t('COMUNIDAD')}
                            </Button>
                            <Button
                                type="primary"
                                className="question__actions-btn"
                                onClick={() => dispatch(logout())}
                            >
                                {t('EXIT')}
                            </Button>
                        </Space>
                    )}
                    footer={(<Content t={t} />)}
                />
            </div>
        </DashboardProvider>
    );
};

export default DashboardHome;
