import { Button } from 'antd';
import {
    AiOutlineMail,
    AiOutlineWhatsApp,
} from 'react-icons/ai';

const Contact = () => {
    const handleAction = (action: string) => {
        let url: string = '';
        switch (action) {
            case 'WHATSAPP':
                url = 'https://wa.me/5548991271973';
                window.open(url, '_blank');
                break;
            case 'EMAIL':
                url = 'mailto:luciano.marcelino@rectoralboard.com';
                window.open(url, '_blank');
                break;
            default:
                break;
        }
    };

    return (
        <div className="floating">
            <Button
                type="primary"
                shape="circle"
                className="floating__button"
                onClick={() => handleAction('WHATSAPP')}
            >
                <AiOutlineWhatsApp color="#201f1e" size={28} />
            </Button>
            <Button
                type="primary"
                shape="circle"
                className="floating__button"
                onClick={() => handleAction('EMAIL')}
            >
                <AiOutlineMail color="#201f1e" size={28} />
            </Button>
        </div>
    );
};

export default Contact;
