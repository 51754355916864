import { IQuestions } from '../interfaces';
import { IAnswer } from '../interfaces/answer.interface';

const QuestionMatchAnswers = ((answers: IAnswer[], questions: IQuestions[]) => {
    const questionsWithAnswers = questions.map((question) => {
        const answersByChapter = answers.filter((answer: IAnswer) => answer?.questionID?.chapter === question.chapterKeyName);
        const answersByBlock = question.block.map((block) => {
            const answersBySection = block.questions.map((questionBlock) => {
                const answer = answersByChapter.find((answer: IAnswer) => answer?.questionID?.keyName === questionBlock.keyName);
                return {
                    ...questionBlock,
                    answer,
                };
            });
            return {
                ...block,
                questions: answersBySection,
            };
        });
        return {
            ...question,
            block: answersByBlock,
        };
    });
    return questionsWithAnswers;
});

const PublicQuestionMatchAnswers = ((publicAnswers: any[], questions: IQuestions[], chapterSelected: string) => {
    const questionsMatched = publicAnswers.map((publicAnswer: any) => {
        console.log({ publicAnswer });
        const questionFounded = questions.find((itemQuestion: IQuestions) => itemQuestion.chapterKeyName === chapterSelected);
        if (questionFounded) {
            questionFounded.block.map((itemBlock: any) => {
                itemBlock.questions.map((itemBlockQuestion: any) => {
                    if (itemBlockQuestion.keyName === publicAnswer.question.keyName) {
                        itemBlockQuestion.answers = publicAnswer.answers;
                    }
                    return itemBlockQuestion;
                });
                return itemBlock;
            });
        }
        return publicAnswer;
    });

    return questionsMatched;
});

const PublicAllQuestionMatchAnswers = ((questionsMatched: any[], questions: IQuestions[], chapterSelected: string) => {
    const allMatched = questions.map((itemQuestion: any) => {
        if (itemQuestion.chapterKeyName === chapterSelected) {
            itemQuestion.block.map((itemBlock: any) => {
                itemBlock.questions.map((itemBlockQuestion: any) => {
                    const questionFounded = questionsMatched.find((item: any) => item.question.keyName === itemBlockQuestion.keyName);
                    if (questionFounded) {
                        itemBlockQuestion.answers = questionFounded.answers;
                    }
                    return itemBlockQuestion;
                });
                return itemBlock;
            });
        }
        return itemQuestion;
    });

    const allMatchedFiltered = allMatched.filter((item: any) => item.chapterKeyName === chapterSelected)[0];

    return allMatchedFiltered;
});

export {
    QuestionMatchAnswers,
    PublicQuestionMatchAnswers,
    PublicAllQuestionMatchAnswers,
};
