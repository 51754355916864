import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    login, setLoading, setError,
} from './authSlice';
import {
    LS_STUDY_GROUP_KEY, LS_TOKEN_KEY, LS_USER_KEY,
} from '../../../constants/localStorageKeys';

import usersAPI from '../../../api/users.api';

type LoginUserPayload = {
    email: string;
    code: string;
    password: string;
};

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (payload: LoginUserPayload, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            const response: any = await usersAPI.login(payload);
            dispatch(login(response));
            localStorage.setItem(LS_USER_KEY, JSON.stringify(response.user));
            localStorage.setItem(LS_STUDY_GROUP_KEY, JSON.stringify(response.studyGroup));
            localStorage.setItem(LS_TOKEN_KEY, JSON.stringify(response.token));
            return response;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const loadUser = createAsyncThunk(
    'auth/loadUser',
    async (token: string, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            const response: any = await usersAPI.load(token);
            dispatch(login(response));
            return response;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);
