const changeToRoman = ((index: number) => {
    const numerosRomanos: { [key: number]: string } = {
        1: 'I',
        4: 'IV',
        5: 'V',
        9: 'IX',
        10: 'X',
        40: 'XL',
        50: 'L',
        90: 'XC',
        100: 'C',
        400: 'CD',
        500: 'D',
        900: 'CM',
        1000: 'M',
    };

    let resultado = '';
    const valoresRomanos = Object.keys(numerosRomanos).reverse();

    for (let i = 0; i < valoresRomanos.length; i += 1) {
        const valor = parseInt(valoresRomanos[i], 10);
        while (index >= valor) {
            resultado += numerosRomanos[valor];
            index -= valor;
        }
    }

    return resultado;
});

export { changeToRoman };
