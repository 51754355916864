import {
    Button, Checkbox, Form, Input, message, notification, Space, Tooltip, Typography,
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DashboardContext } from './context/dashboardContext';
import answersAPI from '../../api/answers.api';

const { Title, Text } = Typography;

const TextEditor = ({ t }: { t: (value: string) => string }) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const {
        currentChapter, questions, questionSelected, valueEditor, setValueEditor, setQuestions,
    } = useContext(DashboardContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [isPublic, setIsPublic] = useState<boolean>(questionSelected?.question?.answer?.isPublic || false);
    const [externalLink, setExternalLink] = useState<string>('N/A');

    const onChange = (value: any) => setValueEditor(value);
    const onChangeCheckbox = (e: CheckboxChangeEvent) => setIsPublic(e.target.checked);

    useEffect(() => {
        setValueEditor('');
        setIsPublic(false);
    }, [currentChapter]);

    const handleUpdate = () => {
        if (!questionSelected) return;
        setLoading(true);
        const { question, chapter } = questionSelected;
        const payload = {
            content: valueEditor,
            externalLink,
            chapter: chapter?.keyName,
            keyName: question?.keyName,
            isPublic,
            answerID: question.answer?._id,
            questionID: question.answer?.questionID?._id,
        };

        answersAPI.updateAnswer(payload)
            .then((response: any) => {
                const { addAuthor: answer } = response;
                if (answer) {
                    const newQuestions = questions.map((item) => {
                        if (item.chapterKeyName === chapter?.keyName) {
                            item.block.map((block) => {
                                block.questions.map((question) => {
                                    if (question.keyName === payload.keyName) {
                                        const dataToUpdate = {
                                            ...answer,
                                        };
                                        dataToUpdate.questionID = {
                                            chapter: chapter?.keyName,
                                            keyName: question?.keyName,
                                            _id: question.answer?.questionID?._id,
                                        };
                                        question.answer = dataToUpdate;
                                    }
                                    return question;
                                });
                                return block;
                            });
                        }
                        return item;
                    });
                    setQuestions(newQuestions);
                }
                notification.success({
                    message: tf('RESPONSES.ANSWER_UPDATED'),
                });
            })
            .catch((err) => {
                const description = err?.response?.data?.message;
                notification.success({
                    message: tf('RESPONSES.ANSWER_UPDATED_FAILED'),
                    description,
                });
            })
            .finally(() => setLoading(false));

    };

    const handleSave = () => {
        if (!questionSelected) return;
        setLoading(true);
        const { question, chapter } = questionSelected;
        const payload = {
            content: valueEditor,
            externalLink,
            chapter: chapter?.keyName,
            keyName: question?.keyName,
            isComplete: true,
            isPublic,
        };
        answersAPI.createAnswer(payload)
            .then((response: any) => {
                const { answerCreated: answer } = response;
                if (answer) {
                    const newQuestions = questions.map((item) => {
                        if (item.chapterKeyName === chapter?.keyName) {
                            item.block.map((block) => {
                                block.questions.map((question) => {
                                    if (question.keyName === payload.keyName) {
                                        question.answer = answer;
                                        if (question.answer && question?.answer?.questionID) {
                                            question.answer.questionID = {
                                                chapter: chapter?.keyName,
                                                keyName: question?.keyName,
                                                _id: answer?.questionID,
                                            };
                                        }
                                    }
                                    return question;
                                });
                                return block;
                            });
                        }
                        return item;
                    });
                    setQuestions(newQuestions);
                }
                notification.success({
                    message: tf('RESPONSES.ANSWER_CREATED'),
                });
            })
            .catch((err) => {
                const description = err?.response?.data?.message;
                notification.success({
                    message: tf('RESPONSES.ANSWER_CREATED_FAILED'),
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    const handleAction = () => {
        if (!valueEditor) {
            message.error(tf('RESPONSES.EMPTY_ANSWER'));
            return;
        }
        if (questionSelected?.question?.answer) handleUpdate();
        else handleSave();
    };

    useEffect(() => {
        if (questionSelected) {
            setValueEditor(questionSelected.question.answer?.content || '');
        }
    }, [questionSelected]);

    useEffect(() => {
        if (questionSelected) {
            setIsPublic(questionSelected.question.answer?.isPublic || false);
        }
    }, [questionSelected]);

    return (
        <Space direction="vertical" size={24}>
            <Button
                block
                type="primary"
                className="editor__button"
                loading={loading}
                onClick={() => handleAction()}
            >
                {t('SAVE_QUESTION')}
            </Button>
            <div className="editor__public">
                <Checkbox
                    onChange={onChangeCheckbox}
                    checked={isPublic}
                >
                    <Space direction="vertical" size={8}>
                        <Title level={4} className="editor__public-title">
                            {t('IS_PUBLIC')}
                        </Title>
                        <Text className="editor__public-text">
                            {t('PUBLIC_ANSWER')}
                        </Text>
                    </Space>
                </Checkbox>
            </div>
            <div className="editor">
                <ReactQuill
                    className="editor__quill"
                    theme="snow"
                    value={valueEditor}
                    onChange={onChange}
                />
            </div>
        </Space>
    );
};

export default TextEditor;
