import { Form, Input } from 'antd';
import Country from '../../widgets/Country';
import PhoneNumber from '../../widgets/PhoneNumber';

interface Props {
    tf: (value: string) => string;
    rules: any;
    rulesEmail: any;
    form: any
}

const Secretary = ({
    tf, rules, rulesEmail, form,
}: Props) => (
    <div style={{ marginTop: 24 }}>
        <Form.Item name="secretaryPassword" hidden initialValue="secretarypassword">
            <Input />
        </Form.Item>

        <Form.Item name="secretaryRole" hidden initialValue={['SECRETARY', 'MEMBER_STUDY_GROUP']}>
            <Input />
        </Form.Item>

        <Form.Item name="secretaryOrigin" hidden initialValue="WEB">
            <Input />
        </Form.Item>

        <Form.Item
            label={tf('INPUTS.NAME')}
            name="secretaryName"
            rules={rules}
        >
            <Input placeholder={tf('INPUTS.NAME_PLACEHOLDER')} />
        </Form.Item>

        <Form.Item
            label={tf('INPUTS.LAST_NAME')}
            name="secretaryLastName"
            rules={rules}
        >
            <Input placeholder={tf('INPUTS.LAST_NAME_PLACEHOLDER')} />
        </Form.Item>

        <Country name="secretaryCountry" />

        <PhoneNumber countryCodeName="secretaryCountryCode" mobileName="secretaryMobile" />

        <Form.Item
            label={tf('INPUTS.EMAIL')}
            name="secretaryEmail"
            rules={rulesEmail}
        >
            <Input placeholder={tf('INPUTS.EMAIL_PLACEHOLDER')} />
        </Form.Item>
    </div>
);

export default Secretary;
