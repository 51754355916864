import { Space, Typography } from 'antd';
import { useState } from 'react';
import { BsJournalBookmarkFill } from 'react-icons/bs';
import { FaArrowRight } from 'react-icons/fa';
import { IChapter } from '../../interfaces';
import ModalWrapper from '../../widgets/ModalWrapper';
import Preview from './Preview';

interface Props {
    chapter: IChapter;
    t: any;
    index: number;
}

const { Title, Text, Paragraph } = Typography;

const Chapter = ({ chapter, t, index }: Props) => {
    const [show, setShow] = useState<boolean>(false);

    return (
        <div className="home__section--summary-chapters-chapter">
            <ModalWrapper
                visible={show}
                onCancel={() => setShow(false)}
            >
                <Preview chapter={chapter} t={t} index={index} />
            </ModalWrapper>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <Space direction="horizontal" size={8}>
                    <BsJournalBookmarkFill color="#FFF" size={24} />
                    <Text className="home__section--summary-chapters-chapter-label">
                        {`${t('CHAPTER')} ${index}`}
                    </Text>
                </Space>

                <Title level={2}>
                    {t(chapter.title)}
                </Title>

                {
                    chapter.thoughts && (
                        <Paragraph
                            className="home__section--summary-chapters-chapter-thoughts"
                            onClick={() => chapter?.thoughts && window.open(chapter?.thoughts, '_blank')}
                        >
                            {t('THOUGHTS')}
                        </Paragraph>
                    )
                }

                <Text
                    className="home__section--summary-chapters-chapter-link"
                    onClick={() => setShow(true)}
                >
                    {t('READ_MORE')}
                    <FaArrowRight />
                </Text>
            </Space>
        </div>
    );
};

export default Chapter;
