import { Space, Typography } from 'antd';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

const { Title, Text } = Typography;

const Header = ({ t }: { t: (value: string) => string }) => {
    const { group } = useSelector((state: any) => state.auth);

    return (
        <Space direction="vertical" size={16}>
            <Title level={3}>
                <Trans
                    t={t}
                    i18nKey="HEADER"
                    components={{
                        span: <span style={{ color: '#86C3FF' }} />,
                    }}
                />
            </Title>

            <Text style={{ fontSize: 16 }}>
                <Trans
                    t={t}
                    i18nKey="DESCRIPTION"
                    components={{
                        b: <b style={{ color: '#86C3FF' }} />,
                    }}
                    values={{ group: group?.code }}
                />
            </Text>
            <Text style={{ fontSize: 16 }}>
                {t('THANK_YOU')}
            </Text>

        </Space>
    );
};

export default Header;
