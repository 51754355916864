import { Typography, Space } from 'antd';
import { BsJournalBookmarkFill } from 'react-icons/bs';
import { IChapter } from '../../interfaces';

interface Props {
    chapter: IChapter;
    index: number;
    t: (t:string) => string;
}

const { Title, Text, Paragraph } = Typography;

const Preview = ({ chapter, index, t }: Props) => {
    const { title, description } = chapter;

    return (
        <div className="modal__summary--preview">
            <Space direction="horizontal" size={8} style={{ marginBottom: 12 }}>
                <BsJournalBookmarkFill color="#FFF" size={24} />
                <Text className="home__section--summary-chapters-chapter-label">
                    {`${t('CHAPTER')} ${index}`}
                </Text>
            </Space>
            <Title level={3} className="modal__summary--preview-title">
                {t(title)}
            </Title>

            <Title level={5} className="modal__summary--preview-title">
                {t('CHAPTER_SUMMARY_SUBTITLE')}
            </Title>

            {
                description && (
                    <Text className="modal__summary--preview-description">
                        {t(description)}
                    </Text>
                )
            }
        </div>
    );
};

export default Preview;
