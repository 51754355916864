import {
    Avatar,
    Space,
    Steps as Steper,
    Typography,
} from 'antd';
import { ReactNode, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FaUsers } from 'react-icons/fa';
import { RiMailSendLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { IStep } from '../../interfaces';
import FormsManager from './FormsManager';

interface Props {
    t: (value: string) => string;
    steps: IStep[];
}

const { Title, Text } = Typography;

const Steps = ({ t, steps }: Props) => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState<number>(0);

    const next = (value: number) => setCurrentStep(value);
    const prev = (value: number) => setCurrentStep(value);

    const ICONS: ReactNode[] = [
        <FaUsers />,
        <RiMailSendLine />,
        <AiFillCheckCircle />,
    ];

    const items: any = steps.map((item, index) => ({
        key: item.stepName,
        icon: ICONS[index],
    }));

    const updatePath = (value: number) => {
        navigate(`/groups/onboarding/${steps[value].tag}`, { replace: true });
    };

    const handleAction = (action: string) => {
        switch (action) {
            case 'next':
                const nextValue = currentStep + 1;
                next(nextValue);
                updatePath(nextValue);
                break;
            case 'prev':
                const prevValue = currentStep - 1;
                prev(prevValue);
                updatePath(prevValue);
                break;
            default:
                break;
        }
    };

    return (
        <div className="steper">
            <Space direction="vertical" size={32}>
                <Steper current={currentStep} items={items} direction="horizontal" responsive={false} />
                <div className="steper__inner">
                    <Title level={2} className="steper__inner--title">
                        {t(steps[currentStep].stepName)}
                    </Title>
                    <Space direction="vertical" size={8} className="steper__inner--info">
                        <Avatar
                            size={100}
                            shape="square"
                            src={ICONS[currentStep]}
                            className="steper__inner--avatar"
                        />
                        <Text className="steper__inner--help">
                            {t(steps[currentStep].help)}
                        </Text>
                    </Space>

                    <FormsManager
                        t={t}
                        currentStep={currentStep}
                        steps={steps}
                        handleAction={(action: string) => handleAction(action)}
                    />
                </div>
            </Space>
        </div>
    );
};

export default Steps;
