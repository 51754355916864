import cs from 'classnames';
import { Avatar, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Mobile from './Mobile';
import IMAGES from '../../constants/images';
import SwitchLanguage from '../../widgets/SwitchLanguage';

interface Items {
    key: number;
    name: string;
    path: string;
    hash?: string;
    active: boolean;
}

const { Text } = Typography;

const Navbar = () => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME' });

    const navigate = useNavigate();

    const location = useLocation();
    const { pathname, hash } = location;

    const [itemActive, setItemActive] = useState<number>(0);

    const ITEMS: Items[] = [
        {
            key: 1,
            name: t('NAVBAR.EXPERIENCES'),
            path: '/',
            hash: '#experiences',
            active: false,
        },
        {
            key: 2,
            name: t('NAVBAR.SUMMARY'),
            path: '/',
            hash: '#summary',
            active: false,
        },
        {
            key: 3,
            name: t('NAVBAR.AUTHOR'),
            path: '/',
            hash: '#author',
            active: false,
        },
        {
            key: 4,
            name: t('NAVBAR.MARKETPLACE'),
            path: '/',
            hash: '#marketplace',
            active: false,
        },
        {
            key: 5,
            name: t('NAVBAR.SCHEDULE'),
            path: '/schedule',
            active: false,
        },
        {
            key: 6,
            name: t('NAVBAR.FEEDBACK'),
            path: '/',
            hash: '#feedback',
            active: false,
        },
        /* {
            key: 3,
            name: t('NAVBAR.CONTACT'),
            path: '/',
            hash: '#contact',
            active: false,
        }, */
        {
            key: 7,
            name: t('NAVBAR.STUDY_GROUPS'),
            path: '/groups/dashboard/home',
            active: false,
        },
    ];

    const [isClosed, setIsClosed] = useState<boolean>(false);

    const toogleClosed = () => setIsClosed(!isClosed);

    useEffect(() => {
        let item;
        if (hash && pathname === '/') {
            let element;
            if (hash.includes('experiences')) {
                item = ITEMS.find((item) => item.path === pathname && item.hash === '#experiences');
                element = document.getElementById('experiences');
            } else {
                item = ITEMS.find((item) => item.path === pathname + hash);
                element = document.getElementById(hash.substring(1));
            }
            if (element) element.scrollIntoView();
        } else if (pathname === '/') {
            item = ITEMS.find((item) => item.path === pathname);
        } else {
            item = ITEMS.find((item) => item.path === pathname);
        }
        if (item) { setItemActive(item.key); }
    }, [location, hash]);

    return (
        <div className="navbar">
            {
                isClosed && (
                    <Mobile
                        isClosed={isClosed}
                        toogleClosed={toogleClosed}
                        items={ITEMS}
                        itemActive={itemActive}
                    />
                )
            }
            <div
                className="navbar__burger"
                onClick={toogleClosed}
            >
                <GiHamburgerMenu />
            </div>
            <Avatar
                size={84}
                shape="square"
                src={IMAGES.logo.book}
                className="navbar__logo"
                onClick={() => navigate('/')}
            />
            <div className="navbar__items">
                {
                    ITEMS.map((item, index) => (
                        <Link
                            key={index}
                            to={item.path + (item.hash || '')}
                            onClick={() => setItemActive(item.key)}
                        >
                            <div className={cs('navbar__items--item', { active: item.key === itemActive })}>
                                <Text className="navbar__items--item-text">
                                    {item.name}
                                </Text>
                                {(item.key === itemActive) && <div className="navbar__items--item-line" />}
                            </div>
                        </Link>
                    ))
                }
            </div>
            <SwitchLanguage />
        </div>
    );
};

export default Navbar;
