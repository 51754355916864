import { useEffect, useState } from 'react';
import {
    Row, Col, Typography, Space, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Chapter from './Chapter';
import getChapters from '../../api/chapters.api';
import { IChapter } from '../../interfaces';

const { Title, Text } = Typography;

const Summary = () => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME.SUMMARY' });

    const [loading, setLoading] = useState<boolean>(false);
    const [chapters, setChapters] = useState<IChapter[]>([]);

    const fetchChapters = () => {
        setLoading(true);
        getChapters()
            .then((response: any) => setChapters(response))
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchChapters();
    }, []);

    return (
        <div className="home__section--summary-chapters" style={{ padding: '24px 0' }} id="summary">
            {
                loading ? (
                    <Space direction="vertical" size={8}>
                        <Spin />
                        <Text>
                            {t('LOADING')}
                        </Text>
                    </Space>
                ) : (
                    <Row gutter={[64, 32]}>
                        {
                            chapters?.map((chapter, index) => (
                                index > 0 ? (
                                    <Col xs={24} md={12} xxl={8} key={index}>
                                        <Chapter chapter={chapter} t={t} index={chapter.number} />
                                    </Col>
                                ) : null
                            ))
                        }
                    </Row>
                )
            }
        </div>
    );

};

export default Summary;
