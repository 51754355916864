import axios, { AxiosPromise } from 'axios';
import { BOOK_API } from '../config';
import { IFeedbackDto } from '../interfaces';

export const createFeedback = (body: IFeedbackDto) => {
    const url = `${BOOK_API}/feedbacks`;
    const response = axios.post(url, body);
    return response;
};

export const getFeedback = () => {
    const url = `${BOOK_API}/feedbacks`;
    const response = axios.get(url);
    return response;
};
