import axios from 'axios';
import { BOOK_API } from '../config';

const getEvents = () => {
    const url = `${BOOK_API}/events`;
    const response = axios.get(url);
    return response;
};

export default getEvents;
