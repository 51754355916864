import { debounce } from 'lodash';
import {
    Col, Row, Space, Spin, Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IReview } from '../../interfaces';
import getReviews from '../../api/reviews.api';
import { IResponseList } from '../../interfaces/response.interface';
import Filters from './Filters';
import ReviewCard from './ReviewCard';
import ModalWrapper from '../../widgets/ModalWrapper';
import Review from './Review';

const { Text } = Typography;

const Reviews = () => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME.REVIEWS' });
    const location = useLocation();
    const { hash } = location;

    const [loading, setLoading] = useState<boolean>(false);
    const [reviews, setReviews] = useState<IReview[]>([]);
    const [review, setReview] = useState<IReview | null>(null);
    const [params, setParams] = useState<{
        page: number,
        limit: number,
        search?: string | null,
        country?: string | null,
    }>({ page: 1, limit: 10 });

    const getParams = () => ({
        page: params.page,
        limit: params.limit,
        search: params.search,
        country: params.country,
    });

    const fetchReviews = (search?: string, country?: string) => {
        setLoading(true);

        const params = {
            ...getParams(),
        };

        if (search) params.search = search;
        if (country) params.country = country;

        getReviews(params)
            .then((response: IResponseList<IReview[]>) => {
                setReviews(response.data);
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    const fetchDebounced = useCallback<any>(debounce(fetchReviews, 700), []);

    const handleSetReview = (review: IReview) => setReview(review);

    const handleSetPaginator = (page: number, limit: number) => setParams({ ...params, page, limit });

    const handleSetSearch = (search: string | null) => {
        setParams({ ...params, search });
        fetchDebounced(search, null);
    };

    const handleSetCountry = (country: string | null) => {
        setParams({ ...params, country });
        fetchDebounced(null, country);
    };

    useEffect(() => {
        fetchReviews();
    }, []);

    useEffect(() => {
        const keyNameReview = hash.split('=')[1];
        if (keyNameReview && reviews.length > 0) {
            const review = reviews.filter((item: IReview) => item.keyName === keyNameReview)[0];
            if (review) setReview(review);
        }
    }, [location, reviews]);

    return (
        <div className="home__section--summary-chapters" style={{ padding: '24px 0' }} id="reviews">
            { review && (
                <ModalWrapper
                    visible={review !== null}
                    onCancel={() => setReview(null)}
                >
                    <Review review={review} />
                </ModalWrapper>
            )}
            <Space direction="vertical" size={40} className="full__width">
                {/* <Filters
                    loading={loading}
                    handleSetSearch={handleSetSearch}
                    handleSetCountry={handleSetCountry}
                /> */}

                {
                    loading ? (
                        <Space direction="vertical" size={8}>
                            <Spin />
                            <Text>
                                {t('LOADING')}
                            </Text>
                        </Space>
                    ) : (
                        <Row gutter={[32, 32]} style={{ width: '100%' }}>
                            {
                                reviews.map((item: IReview, index: number) => (
                                    <Col xs={24} md={12} xxl={8} key={index}>
                                        <ReviewCard
                                            item={item}
                                            handleSetReview={handleSetReview}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    )
                }
            </Space>
        </div>
    );
};

export default Reviews;
