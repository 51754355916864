import { IChapter } from '../interfaces';

export const CHAPTERS: IChapter[] = [
    {
        number: 0,
        keyName: 'RECTORY_CHALLENGE',
        title: 'CHAPTER_RECTORY_CHALLENGE',
        totalAnswers: 0,
    },
    {
        number: 1,
        keyName: 'CHAPTER_1',
        title: 'CHAPTER_TITLE_1',
        description: 'CHAPTER_DESCRIPTION_1',
        totalAnswers: 0,
    },
    {
        number: 2,
        keyName: 'CHAPTER_2',
        title: 'CHAPTER_TITLE_2',
        description: 'CHAPTER_DESCRIPTION_2',
        totalAnswers: 0,
    },
    {
        number: 3,
        keyName: 'CHAPTER_3',
        title: 'CHAPTER_TITLE_3',
        description: 'CHAPTER_DESCRIPTION_3',
        totalAnswers: 0,
    },
    {
        number: 4,
        keyName: 'CHAPTER_4',
        title: 'CHAPTER_TITLE_4',
        description: 'CHAPTER_DESCRIPTION_4',
        totalAnswers: 0,
    },
    {
        number: 5,
        keyName: 'CHAPTER_5',
        title: 'CHAPTER_TITLE_5',
        description: 'CHAPTER_DESCRIPTION_5',
        totalAnswers: 0,
    },
    {
        number: 6,
        keyName: 'CHAPTER_6',
        title: 'CHAPTER_TITLE_6',
        description: 'CHAPTER_DESCRIPTION_6',
        totalAnswers: 0,
    },
    {
        number: 7,
        keyName: 'CHAPTER_7',
        title: 'CHAPTER_TITLE_7',
        description: 'CHAPTER_DESCRIPTION_7',
        totalAnswers: 0,
    },
    {
        number: 8,
        keyName: 'CHAPTER_8',
        title: 'CHAPTER_TITLE_8',
        description: 'CHAPTER_DESCRIPTION_8',
        totalAnswers: 0,
    },
    {
        number: 9,
        keyName: 'CHAPTER_9',
        title: 'CHAPTER_TITLE_9',
        description: 'CHAPTER_DESCRIPTION_9',
        totalAnswers: 0,
    },
    {
        number: 10,
        keyName: 'CHAPTER_10',
        title: 'CHAPTER_TITLE_10',
        description: 'CHAPTER_DESCRIPTION_10',
        totalAnswers: 0,
    },
];
