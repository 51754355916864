import {
    Button, Form, message, Space, Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { IStep } from '../../interfaces';
import {
    formatGroupData, validateExistMembers,
} from '../../utils/group.util';
import groupsAPI from '../../api/groups.api';

interface Props {
    t: (value: string) => string;
    steps: IStep[];
    currentStep: number;
    handleAction: (action: string) => void;
}

const { Provider } = Form;
const { Text } = Typography;

const FormsManager = ({
    t, steps, currentStep, handleAction,
}: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [group, setGroup] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const isFinish = currentStep === steps.length - 2;

    const renderComponent = (props: any) => {
        const Component = steps[currentStep].component;
        return <Component setData={() => { }} {...props} />;
    };

    const handleSubmit = async (values: any) => {
        setLoading(true);
        const payload = {
            ...group,
            ...values,
        };
        const groupData = formatGroupData(payload);
        if (!groupData.secretary.email || groupData.secretary.email === '') {
            message.warning(tf('RESPONSES.SECRETARY_REQUIRED'));
            setLoading(false);
            return;
        }

        if (!groupData.members || groupData.members?.length === 0) {
            message.warning(tf('RESPONSES.MEMBERS_REQUIRED'));
            setLoading(false);
            return;
        }

        if (groupData.secretary.email === groupData.leadership.email) {
            message.warning(tf('RESPONSES.SAME_EMAILS'));
            setLoading(false);
            return;
        }

        const groupToSave = await validateExistMembers(groupData);

        groupsAPI.createStudyGroup({
            name: groupToSave.nameGroup,
            members: groupToSave.members,
            email: groupToSave.leadership.email,
            secretaryEmail: groupToSave.secretary.email,
        }).then((res) => {
            message.success(tf('RESPONSES.STUDYGROUP_CREATED'));
            navigate('/groups/onboarding/start-studying', { replace: true });
            handleAction('next');
        }).catch((err) => {
            const description = err?.response?.data?.message || err?.message;
            message.error(tf(`RESPONSES.${description}`));
        }).finally(() => setLoading(false));
    };

    const handleFormData = () => form.submit();

    return (
        <Space direction="vertical" size={24}>
            <Provider
                onFormFinish={(name, { values }) => {
                    setGroup((group: any) => ({ ...group, ...values }));
                    if (isFinish) handleSubmit(values);
                    else handleAction('next');
                }}
            >
                {renderComponent({
                    form, group, setGroup, handleFormData,
                })}
            </Provider>

            {
                currentStep === steps.length - 1 ? (
                    <Button
                        block
                        type="primary"
                        loading={loading}
                        onClick={() => navigate('/groups/login', { replace: true })}
                    >
                        {t('START')}
                    </Button>
                ) : (
                    <Button
                        block
                        type="primary"
                        loading={loading}
                        onClick={handleFormData}
                    >
                        {t(isFinish ? 'FINISH' : 'NEXT')}
                    </Button>
                )
            }

            {
                currentStep > 0 && currentStep !== 2 && (
                    <Text
                        onClick={() => handleAction('prev')}
                        className="steper__inner--back"
                    >
                        <BsArrowLeft size={16} color="#86C3FF" />
                        {t('BACK')}
                    </Text>
                )
            }
        </Space>
    );
};

export default FormsManager;
