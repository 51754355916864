import { DASHBOARD_IMAGES } from '../config';

const BUCKET_URL = DASHBOARD_IMAGES;
const IMAGES = {
    logo: {
        rectoral: `${BUCKET_URL}/logos/logo-light.svg`,
        viseu: `${BUCKET_URL}/logos/viseu.svg`,
        book: `${BUCKET_URL}/book/logo-book.svg`,
    },
    vcard: `${BUCKET_URL}/illustrations/vcard-code.png`,
    author: `${BUCKET_URL}/illustrations/luciano.png`,
    book: `${BUCKET_URL}/book/book-portrait.svg`,
    available: {
        printed: {
            viseu: `${BUCKET_URL}/book/viseu.svg`,
            amazon: `${BUCKET_URL}/book/amazon.svg`,
            americanas: `${BUCKET_URL}/book/americanas.svg`,
            shoptime: `${BUCKET_URL}/book/shoptime.svg`,
            submarino: `${BUCKET_URL}/book/submarino.svg`,
            magalu: `${BUCKET_URL}/book/magalu.svg`,
        },
        digital: {
            amazon: `${BUCKET_URL}/book/amazon.svg`,
            apple: `${BUCKET_URL}/book/apple.svg`,
            barnes: `${BUCKET_URL}/book/barnes%26noble.svg`,
            google_play: `${BUCKET_URL}/book/google-play.svg`,
            livraria: `${BUCKET_URL}/book/livraria.svg`,
            rakuten: `${BUCKET_URL}/book/rakuten.svg`,
            wook: `${BUCKET_URL}/book/wook.svg`,
        },
    },
};

export default IMAGES;
