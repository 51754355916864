import axios from 'axios';
import { BOOK_API } from '../config';

const getChapters = () => {
    const url = `${BOOK_API}/chapters`;
    const response = axios.get(url);
    return response;
};

export default getChapters;
