/* global React JSX */
import axios from 'axios';

import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from '../store/slices/auth';
import { LS_TOKEN_KEY } from '../constants/localStorageKeys';

interface Props {
    children: JSX.Element;
}

const PrivateRoute = ({ children }: Props) => {
    const { user, token, loading } = useSelector((state: any) => state.auth);
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    useEffect(() => {
        const localStorageToken = localStorage.getItem(LS_TOKEN_KEY);
        const token = localStorageToken && JSON.parse(localStorageToken);

        if (token) {
            dispatch(loadUser(token));
        }
    }, [dispatch]);

    useEffect(() => {
        if (!user?._id) return;
        axios.defaults.headers.common.authorization = `Bearer ${token}`;
    }, [user]);

    if (loading) return <div>Loading...</div>;
    if (!user?._id) return <Navigate to="/groups/login" />;

    return children;
};

export default PrivateRoute;
