import { MainCommunity } from '../components/Community';
import { CommunityProvider } from '../components/Community/context/communityContext';

const Community = () => (
    <CommunityProvider>
        <MainCommunity />
    </CommunityProvider>
);

export default Community;
