import {
    Typography, Image, Row, Col, Space, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_PRINTED, AVAILABLE_DIGITAL } from '../../constants/availables';

const { Title } = Typography;

const Available = () => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME' });

    return (
        <div className="home__section home__section--available" id="marketplace">
            <Title level={1}>
                {t('AVAILABLE.HEADER')}
            </Title>

            <div className="home__section--available-logos">
                <Space direction="vertical" size={24}>
                    <Title level={2} style={{ textAlign: 'center' }}>
                        {t('AVAILABLE.PRINTED')}
                    </Title>
                    <Row align="middle" justify="space-around" gutter={[24, 48]} style={{ width: '100%' }}>
                        {
                            AVAILABLE_PRINTED.map((item: any, index: number) => (
                                <Col className="gutter-row" xs={12} md={8} xl={4} xxl={6}>
                                    <Tooltip title={item.name} placement="top">
                                        <Image
                                            src={item.logo}
                                            preview={false}
                                            onClick={() => window.open(item.url, '_blank')}
                                        />
                                    </Tooltip>
                                </Col>
                            ))
                        }
                    </Row>
                </Space>

                <Space direction="vertical" size={24}>
                    <Title level={2} style={{ textAlign: 'center' }}>
                        {t('AVAILABLE.DIGITAL')}
                    </Title>

                    <Row align="middle" justify="space-around" gutter={[24, 48]} style={{ width: '100%' }}>
                        {
                            AVAILABLE_DIGITAL.map((item: any, index: number) => (
                                <Col className="gutter-row" xs={12} md={8} xl={4} xxl={6} key={index}>
                                    <Tooltip title={item.name} placement="top">
                                        <Image
                                            src={item.logo}
                                            preview={false}
                                            onClick={() => window.open(item.url, '_blank')}
                                        />
                                    </Tooltip>
                                </Col>
                            ))
                        }
                    </Row>
                </Space>

            </div>
        </div>
    );
};

export default Available;
