// @ts-ignore
import ImageGallery from 'react-image-gallery';
import {
    Col, PageHeader, Pagination, Row, Space, Spin, Tooltip, Typography, message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { FaShare } from 'react-icons/fa';
import { ExperiencesContext } from './context/experiencesContext';
import Location from './Location';
import Members from './Members';
import { IExperience } from '../../interfaces';
import Links from './Links';

const { Title, Paragraph } = Typography;

const Experiences = () => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME.EXPERIENCES' });

    const {
        experiences,
        loading,
        total,
        paginator,
        onPageChange,
        pathToShare,
        fetchExperiences,
    } = useContext(ExperiencesContext);

    const shareExperience = async () => {
        const url = `${window.location.origin}${pathToShare}`;
        try {
            await navigator.clipboard.writeText(url);
            message.info({
                content: t('LINK_COPIED'),
            });
        } catch (error) {
            message.info({
                content: t('LINK_COPIED_ERROR'),
            });
        }
    };

    useEffect(() => {
        fetchExperiences();
    }, [paginator]);

    return (
        <div className="home__section home__section--experiences" id="experiences">
            <Space direction="vertical" size={24}>
                <Title level={1}>
                    {t('HEADER')}
                </Title>
                {
                    loading ? (
                        <div className="home__section--experiences-loading">
                            <Spin />
                            <Title level={4}>
                                {t('LOADING')}
                            </Title>
                        </div>
                    ) : total === 0 ? (
                        <div className="home__section--experiences-empty">
                            <Title level={4}>
                                {t('EMPTY')}
                            </Title>
                        </div>
                    ) : (
                        experiences.map((experience: IExperience) => (
                            <Space direction="vertical" size={32} key={experience._id}>
                                <Row gutter={[32, 40]}>
                                    <Col xs={24} xl={10} xxl={8}>
                                        <div className="home__section--experiences-pictures">
                                            {
                                                experience.pictures.length === 0 ? (
                                                    <div className="home__section--experiences-pictures-empty">
                                                        <Title level={4}>
                                                            {t('NO_PICTURES')}
                                                        </Title>
                                                    </div>
                                                ) : (
                                                    <ImageGallery
                                                        items={experience.pictures}
                                                        loading={loading}
                                                        showPlayButton={false}
                                                        showNav={false}
                                                    />
                                                )
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={24} xl={14} xxl={16}>
                                        <div className="home__section--experiences-content">
                                            <Row gutter={[16, 12]} style={{ width: '100%' }} align="top">
                                                <PageHeader
                                                    title={(
                                                        <Title level={2} style={{ marginBottom: 0 }}>
                                                            {experience.name}
                                                        </Title>
                                                    )}
                                                    style={{ padding: 0, widows: '100%' }}
                                                    extra={[
                                                        <Tooltip title={t('SHARE')} placement="leftTop">
                                                            <FaShare
                                                                color="#86C3FF"
                                                                size={24}
                                                                style={{ verticalAlign: 'middle' }}
                                                                onClick={shareExperience}
                                                            />
                                                        </Tooltip>,
                                                    ]}
                                                />
                                            </Row>

                                            <Row gutter={[16, 28]} style={{ width: '100%' }} align="top">
                                                <Col span={24}>
                                                    <Location
                                                        country={experience.country}
                                                        city={experience.city}
                                                        place={experience.place}
                                                        eventDate={experience.visitDate}
                                                    />
                                                </Col>
                                            </Row>

                                            <Space direction="vertical" size={8}>
                                                <Title level={3}>
                                                    {`${t('EXTERNAL_LINKS')} (${experience.externalLinks.length || 0})`}
                                                </Title>
                                                {
                                                    experience.externalLinks.length === 0 ? (
                                                        <Paragraph className="home__section--experiences-description">
                                                            {t('NO_EXTERNAL_LINKS')}
                                                        </Paragraph>
                                                    ) : (
                                                        <Links links={experience.externalLinks} />
                                                    )
                                                }
                                            </Space>

                                            <Space direction="vertical" size={8}>
                                                <Title level={3}>
                                                    {t('SUMMARY')}
                                                </Title>
                                                <Paragraph className="home__section--experiences-description">
                                                    {experience.summary}
                                                </Paragraph>
                                            </Space>

                                            <Space direction="vertical" size={8}>
                                                <Title level={3}>
                                                    {`${t('MEMBERS')} (${experience.members.length || 0})`}
                                                </Title>
                                                <div className="home__section--experiences-members">
                                                    <Members members={experience.members} />
                                                </div>
                                            </Space>

                                        </div>
                                    </Col>
                                </Row>

                                <Row className="home__section--experiences-pagination">
                                    <Pagination
                                        total={total}
                                        pageSize={paginator.limit}
                                        current={paginator.page}
                                        onChange={onPageChange}
                                        showSizeChanger={false}
                                    />
                                </Row>

                                <Row className="home__section--experiences-footer">
                                    <Paragraph className="home__section--experiences-description">
                                        {t('FOOTER')}
                                    </Paragraph>
                                </Row>
                            </Space>
                        ))
                    )
                }
            </Space>
        </div>
    );
};

export default Experiences;
