/* global JSX React */
import { Modal } from 'antd';
import { IoMdCloseCircle } from 'react-icons/io';

interface Props {
    visible: boolean;
    children: React.ReactNode;
    onCancel: () => void;
}

const ModalWrapper = ({ visible, children, onCancel }: Props): JSX.Element => {
    const closableIcon = () => (
        <IoMdCloseCircle
            size={24}
            color="#FFF"
            style={{ marginTop: 12 }}
            onClick={() => onCancel()}
        />
    );

    return (
        <Modal
            width={800}
            centered
            open={visible}
            onCancel={onCancel}
            closeIcon={closableIcon()}
            footer={null}
        >
            <div className="modal__wrapper--body">
                {children}
            </div>
        </Modal>
    );
};

export default ModalWrapper;
