import moment from 'moment';
import {
    Avatar, Button, Divider, List, Space, Typography, message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { IReview } from '../../interfaces';
import { COUNTRIES } from '../../utils/countries.util';
import { DASHBOARD_URL } from '../../config';

const { Title, Text } = Typography;

const Review = ({ review }: { review: IReview | null }) => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME.REVIEWS' });

    if (!review) return null;

    const {
        title, review: content, externalLink, picture, jobPosition, name, lastName,
        createdAt, publishDate, email, _id, country, keyName,
    } = review;

    const date = moment(publishDate || createdAt).format('DD MMM HH:mm');

    const findImage = (country: string) => COUNTRIES.filter((item: any) => item.code === country)[0].flag;

    const copyToClipboard = () => {
        const url = `${DASHBOARD_URL}/#reviews?key=${keyName}`;
        navigator.clipboard.writeText(url);
        message.success(t('COPIED_TO_CLIPBOARD'));
    };

    return (
        <Space direction="vertical" size={32} className="full__size">
            <Space direction="vertical" size={8} className="full__size">
                <Title level={3} className="home__section--feedback-card-title no__margin">
                    {
                        !title ? (
                            t('REVIEW_DETAIL')
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: title }} />
                        )
                    }
                </Title>
                <Text className="home__section--feedback-card-date">
                    {`${t('PUBLISHED_AT')} ${date}`}
                </Text>
                <div className="home__section--review-content">
                    <Text className="home__section--feedback-modal-description">
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </Text>
                </div>
                <Space size={16}>
                    {
                        externalLink && (
                            <Button
                                type="link"
                                target="_blank"
                                href={externalLink}
                                style={{ width: 'fit-content' }}
                            >
                                {t('EXTERNAL_REVIEW')}
                            </Button>
                        )
                    }
                    {
                        !externalLink && (
                            <Button type="ghost" onClick={copyToClipboard}>
                                {t('SHARE')}
                            </Button>
                        )
                    }
                </Space>
                <Divider />
                <List.Item
                    key={_id}
                    extra={[
                        <Avatar
                            className="home__section--review-flag"
                            src={findImage(country)}
                            shape="square"
                        />,
                    ]}
                >
                    <List.Item.Meta
                        className="full__width"
                        avatar={
                            picture?.original ? (
                                <Avatar
                                    size={48}
                                    src={picture?.original}
                                    className="home__section--feedback-card-avatar"
                                />
                            ) : (
                                <Avatar
                                    size={48}
                                    className="home__section--feedback-card-avatar"
                                >
                                    {name.charAt(0).toUpperCase()}
                                </Avatar>
                            )
                        }
                        title={(
                            <Title level={5} className="home__section--feedback-card-title no__margin">
                                {`${name} ${lastName} - ${jobPosition}`}
                            </Title>
                        )}
                        description={(
                            <Text className="home__section--feedback-card-description">
                                {email}
                            </Text>
                        )}
                    />
                </List.Item>
            </Space>
        </Space>
    );
};

export default Review;
