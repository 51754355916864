import { Form, Input } from 'antd';
import Country from '../../widgets/Country';
import PhoneNumber from '../../widgets/PhoneNumber';

interface Props {
    tf: (value: string) => string;
    rules: any;
    rulesEmail: any;
    form: any
}

const Leadership = ({
    tf, rules, rulesEmail, form,
}: Props) => (
    <div style={{ marginTop: 24 }}>
        <Form.Item name="leadershipPassword" hidden initialValue="leadershippassword">
            <Input />
        </Form.Item>

        <Form.Item name="leadershipRole" hidden initialValue={['MEMBER_STUDY_GROUP', 'LEADER']}>
            <Input />
        </Form.Item>

        <Form.Item name="leadershipOrigin" hidden initialValue="WEB">
            <Input />
        </Form.Item>

        <Form.Item
            label={tf('INPUTS.NAME')}
            name="leadershipName"
            rules={rules}
        >
            <Input placeholder={tf('INPUTS.NAME_PLACEHOLDER')} />
        </Form.Item>

        <Form.Item
            label={tf('INPUTS.LAST_NAME')}
            name="leadershipLastName"
            rules={rules}
        >
            <Input placeholder={tf('INPUTS.LAST_NAME_PLACEHOLDER')} />
        </Form.Item>

        <Country name="leadershipCountry" />

        <PhoneNumber countryCodeName="leadershipCountryCode" mobileName="leadershipMobile" />

        <Form.Item
            label={tf('INPUTS.EMAIL')}
            name="leadershipEmail"
            rules={rulesEmail}
        >
            <Input placeholder={tf('INPUTS.EMAIL_PLACEHOLDER')} />
        </Form.Item>
    </div>
);

export default Leadership;
