import { useEffect, useState } from 'react';
import { Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Summary from '../Summary';
import Reviews from '../Reviews';

interface IItem {
    key: string;
    label: string;
    children: React.ReactNode;
}

const { TabPane } = Tabs;
const { Title } = Typography;

const ChaptersReviews = () => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME' });
    const [activeKey, setActiveKey] = useState<string>('SUMMARY');
    const location = useLocation();

    const { hash } = location;

    const items: IItem[] = [
        {
            key: 'SUMMARY',
            label: t('SUMMARY.HEADER'),
            children: <Summary />,
        },
        {
            key: 'REVIEWS',
            label: t('REVIEWS.HEADER'),
            children: <Reviews />,
        },
    ];

    useEffect(() => {
        const isReviewsPath = hash.includes('reviews');
        if (isReviewsPath) setActiveKey('REVIEWS');
    }, [hash]);

    return (
        <div className="home__section home__section--summary">
            <Tabs
                activeKey={activeKey}
                onChange={(key) => {
                    setActiveKey(key);
                    window.location.hash = key.toLowerCase();
                }}
            >
                {
                    items.map((item) => (
                        <TabPane
                            tab={(
                                <Title level={1}>
                                    {item.label}
                                </Title>
                            )}
                            key={item.key}
                        >
                            {item.children}
                        </TabPane>
                    ))
                }
            </Tabs>
        </div>
    );
};

export default ChaptersReviews;
