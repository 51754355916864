import axios from 'axios';
import {
    DASHBOARD_API,
    ADMIN_TOKEN,
    BOOK_API,
} from '../config';

const getUsers = (params: any) => {
    const url = `${DASHBOARD_API}/users`;
    const response = axios.get(url, {
        params,
        headers: {
            Authorization: `Bearer ${ADMIN_TOKEN}`,
        },
    });
    return response;
};

const getUser = (userID: string) => {
    const url = `${DASHBOARD_API}/users/${userID}`;
    const response = axios.get(url, {
        headers: {
            Authorization: `Bearer ${ADMIN_TOKEN}`,
        },
    });
    return response;
};

const createUser = (payload: any) => {
    const url = `${DASHBOARD_API}/users/signup`;
    const response = axios.post(url, payload);
    return response;
};

const login = (payload: any) => {
    const url = `${BOOK_API}/study-groups/login`;
    const response = axios.post(url, payload);
    return response;
};

const load = (token: string) => {
    const url = `${BOOK_API}/study-groups/test-token`;
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const response = axios.get(url, { headers });
    return response;
};

export default {
    getUsers,
    getUser,
    createUser,
    login,
    load,
};
