import cs from 'classnames';
import {
    Avatar, Button, Col, Divider, Form, Input, List, message, Row, Spin, Tooltip, Typography,
} from 'antd';
import { debounce } from 'lodash';
import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { FaMinus, FaUserCircle } from 'react-icons/fa';
import { AiOutlineUserAdd } from 'react-icons/ai';
import InfiniteScroll from 'react-infinite-scroll-component';
import usersAPI from '../../api/users.api';
import { DASHBOARD_URL } from '../../config';
import ExternalMember from './ExternalMember';
import ModalWrapper from '../../widgets/ModalWrapper';

interface Props {
    tf: (value: string) => string;
    rules: any;
    rulesEmail: any;
    form: any;
    group: any;
}

const { Title, Text } = Typography;
const { Search } = Input;

const Members = ({
    tf, rules, rulesEmail, form, group,
}: Props) => {
    const [users, setUsers] = useState<any>([]);
    const [maxUsers, setMaxUsers] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState();
    const [members, setMembers] = useState<any>([]);
    const [show, setShow] = useState<boolean>(false);

    const scrollableUsersRef = useRef<HTMLInputElement | null>(null);
    const LIMIT = 15;

    const fetchUsers = (value?: string) => {
        if (loading) return;
        setLoading(true);
        const PAGE = Math.ceil(users.length / LIMIT);

        const params: any = {
            limit: LIMIT,
            page: PAGE + 1,
        };

        if (value) {
            params.search = value;
        }

        usersAPI.getUsers(params)
            .then((response: any) => {
                const { data, meta } = response;
                setMaxUsers(meta.itemCount);
                setUsers([...users, ...data]);
            })
            .catch((err: any) => console.log(err))
            .finally(() => setLoading(false));
    };

    const fetchDebounced = useCallback<any>(debounce(fetchUsers, 700), []);

    const handleSearch = (e: any) => {
        const { value } = e.target;
        setSearch(value);
        fetchDebounced(value);
    };

    const addToMembers = (user: any) => {
        if (members.length === 5) {
            message.warning(tf('RESPONSES.MAX_MEMBERS'));
        } else {
            setMembers([...members, user]);
        }
    };

    const existInGroup = (userID: string) => {
        const user = members.find((member: any) => member._id === userID);
        return !!user;
    };

    const removeFromMembers = (userID: string) => {
        const newMembers = members.filter((member: any) => member._id !== userID);
        setMembers(newMembers);
    };

    const copyToClippboard = () => {
        const url = `${DASHBOARD_URL}/signup`;
        navigator.clipboard.writeText(url);
        message.success(tf('RESPONSES.COPIED_TO_CLIPBOARD'));
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        form.setFieldsValue({ members });
    }, [members]);

    useEffect(() => {
        if (group && group.members) setMembers(group.members);
    }, []);

    const addedMembers = (externalMember: any) => {
        setMembers([...members, externalMember]);
        setShow(false);
    };

    return (
        <Row
            justify="space-between"
            align="top"
            className="full__width"
            gutter={[24, 40]}
            style={{ marginTop: 24 }}
        >
            <ModalWrapper
                visible={show}
                onCancel={() => setShow(false)}
            >
                <ExternalMember addedMember={addedMembers} />
            </ModalWrapper>

            <Form.Item hidden name="members">
                <Input />
            </Form.Item>
            <Col xs={24} xl={12}>
                <Title level={4} style={{ textAlign: 'center' }}>
                    {tf('INPUTS.RECTORAL_MEMBERS')}
                </Title>

                <Tooltip placement="top" title={tf('HELPS.INVITE_MEMBER')}>
                    <Text
                        onClick={() => copyToClippboard()}
                        className="invite__member"
                    >
                        {tf('HELPS.INVITE')}
                    </Text>
                </Tooltip>

                <Row justify="space-between" align="middle">
                    <Col xs={20}>
                        <Search
                            onChange={handleSearch}
                            enterButton
                            placeholder={tf('INPUTS.SEARCH')}
                            style={{ margin: '24px 0' }}
                        />
                    </Col>
                    <Col xs={4}>
                        <Button
                            type="primary"
                            style={{ margin: 'auto' }}
                            onClick={() => setShow(true)}
                        >
                            <AiOutlineUserAdd size={20} />
                        </Button>
                    </Col>
                </Row>

                <div
                    id="scrollableUsers"
                    ref={scrollableUsersRef}
                    className="scrollable__container"
                >
                    <InfiniteScroll
                        dataLength={users.length}
                        next={fetchUsers}
                        hasMore={users.length < maxUsers}
                        loader={loading && <Spin />}
                        endMessage={!loading && <Divider plain>{tf('RESPONSES.NO_MORE_USERS')}</Divider>}
                        scrollableTarget="scrollableUsers"
                    >
                        <List
                            className="scrollable__list"
                            dataSource={users}
                            renderItem={(user: any) => (
                                <List.Item
                                    id={user._id}
                                    className={cs(
                                        'scrollable__list--item',
                                        { active: existInGroup(user._id) },
                                    )}
                                    onClick={() => addToMembers(user)}
                                >
                                    <List.Item.Meta
                                        avatar={(
                                            <Avatar
                                                size={45}
                                                src={user.image.thumbnail || <FaUserCircle color="#86C3FF" size={45} />}
                                                className="scrollable__list--item-avatar"
                                            />
                                        )}
                                        title={(
                                            <Text className="scrollable__list--item-title">
                                                {`${user.name.toLowerCase()} ${user.lastName.toLowerCase()}`}
                                            </Text>
                                        )}
                                        description={(
                                            <Text className="scrollable__list--item-description">
                                                {user.email}
                                            </Text>
                                        )}
                                    />
                                    { !existInGroup(user._id) && <BsPlusLg color="#86C3FF" size={16} /> }
                                </List.Item>
                            )}
                        />
                    </InfiniteScroll>
                </div>
            </Col>
            <Col xs={24} xl={12}>
                <Title level={4} style={{ textAlign: 'center' }}>
                    {tf('INPUTS.GROUP_MEMBERS')}
                </Title>

                <List
                    className="scrollable__list"
                    style={{ paddingLeft: 24 }}
                    dataSource={members}
                    renderItem={(user: any) => (
                        <List.Item
                            id={user._id}
                            className="scrollable__list--item"
                            onClick={() => removeFromMembers(user._id)}
                        >
                            <List.Item.Meta
                                avatar={(
                                    <Avatar
                                        size={45}
                                        src={user.image.thumbnail || <FaUserCircle color="#86C3FF" size={45} />}
                                        className="scrollable__list--item-avatar"
                                    />
                                )}
                                title={(
                                    <Text className="scrollable__list--item-title">
                                        {`${user.name.toLowerCase()} ${user.lastName.toLowerCase()}`}
                                    </Text>
                                )}
                                description={(
                                    <Text className="scrollable__list--item-description">
                                        {user.email}
                                    </Text>
                                )}
                            />
                            <FaMinus color="#d13438" size={16} />
                        </List.Item>
                    )}
                />

            </Col>
        </Row>
    );
};

export default Members;
