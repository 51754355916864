import {
    Avatar, List, Space, Typography,
} from 'antd';
import moment from 'moment';
import { IFeedback } from '../interfaces';
import { COUNTRIES } from '../utils/countries.util';

interface Props {
    feedback: IFeedback;
    onClick: (feedback: IFeedback) => void;
}

const { Item } = List;
const { Text, Title } = Typography;

const FeedbackCard = ({ feedback, onClick }: Props) => {
    const findImage = (country: string) => COUNTRIES.filter((item: any) => item.code === country)[0].flag;

    return (
        <div
            key={feedback?._id}
            className="home__section--feedback-card"
            onClick={() => onClick(feedback)}
        >
            <Avatar
                className="home__section--feedback-card-flag"
                src={findImage(feedback?.country)}
                shape="square"
            />
            <Item.Meta
                avatar={
                    feedback.image ? (
                        <Avatar
                            size={64}
                            src={feedback?.image}
                            className="home__section--feedback-card-avatar"
                        />
                    ) : (
                        <Avatar
                            size={48}
                            className="home__section--feedback-card-avatar"
                        >
                            {feedback.name.charAt(0).toUpperCase()}
                        </Avatar>
                    )
                }
                title={(
                    <Title level={4} className="home__section--feedback-card-title">
                        {`${feedback.name} ${feedback.lastName} - ${feedback.jobPosition}`}
                    </Title>
                )}
                description={(
                    <Space direction="vertical" size={8}>
                        <Text className="home__section--feedback-card-description">
                            {feedback.comment}
                        </Text>
                        <Text className="home__section--feedback-card-date">
                            {moment(feedback.createdAt).format('lll')}
                        </Text>
                    </Space>
                )}
            />
        </div>
    );
};

export default FeedbackCard;
