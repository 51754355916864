import { Typography } from 'antd';
import { useContext } from 'react';
import { DashboardContext } from './context/dashboardContext';
import CardQuestion from './Question';

interface Props {
    t: (value: string) => string;
}

const { Title, Text } = Typography;

const ManageQuestions = ({ t }: Props) => {
    const { questions, currentChapter } = useContext(DashboardContext);

    const currentQuestions = questions.filter((question) => question?.chapterKeyName === currentChapter);
    const { chapter, section, block } = currentQuestions[0];

    return (
        <div className="questions">
            <Title level={3} className="questions__section">
                {
                    section
                        ? t(section)
                        : t(chapter)
                }
            </Title>

            <div className="questions__list">
                {
                    block.map((itemBlock, indexBlock) => (
                        <div className="questions__item" key={indexBlock}>
                            <Text className="questions__item-section">
                                { itemBlock.section && (t(itemBlock.section)) }
                            </Text>
                            {
                                itemBlock.questions.map((question, index) => (
                                    <CardQuestion
                                        t={t}
                                        key={index}
                                        index={indexBlock}
                                        chapter={chapter}
                                        section={itemBlock.section || ''}
                                        chapterKeyName={currentChapter}
                                        question={question}
                                    />
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default ManageQuestions;
