import axios from 'axios';
import { BOOK_API } from '../config';

const getAnswers = () => {
    const url = `${BOOK_API}/answers`;
    const response = axios.get(url);
    return response;
};

const createAnswer = (answer: any) => {
    const url = `${BOOK_API}/answers`;
    const response = axios.post(url, answer);
    return response;
};

const updateAnswer = (answer: any) => {
    const url = `${BOOK_API}/answers`;
    const response = axios.patch(url, answer);
    return response;
};

const getAllAnswers = () => {
    const url = `${BOOK_API}/answers/statistics`;
    const response = axios.get(url);
    return response;
};

const getPublicAnswers = (params: any) => {
    const query = new URLSearchParams(params);
    const url = `${BOOK_API}/answers/public`;
    const response = axios.get(url, { params: query });
    return response;
};

export default {
    getAnswers,
    createAnswer,
    updateAnswer,
    getAllAnswers,
    getPublicAnswers,
};
