import {
    Typography, Avatar, List, Button, Space, message,
} from 'antd';
import moment from 'moment';
import { AiOutlineCalendar } from 'react-icons/ai';
import { IEvent } from '../interfaces';
import { COUNTRIES } from '../utils/countries.util';

const { Text, Title } = Typography;

interface Props {
    event: IEvent;
    t: (key: string) => string;
}

const EventCard = ({ event, t }: Props) => {
    const time = moment(event?.date).format('HH:mm a');
    const date = moment(event?.date).format('LL');

    const handleLink = () => {
        if (event?.meet) {
            window.open(event?.meet, '_blank');
        } else {
            message.info(t('NO_LINK'));
        }
    };

    const findCountry = (country: string) => COUNTRIES.filter((item: any) => item.code === country)[0];

    return (
        <div className="card__event" key={event?.keyName}>
            <List.Item.Meta
                avatar={(
                    <Avatar
                        src={findCountry(event?.country).flag}
                        size={64}
                        className="card__event--content-avatar"
                    />
                )}
                title={(
                    <Space direction="vertical" size={0}>
                        <Title level={4} className="card__event--content-title">
                            {event?.name}
                        </Title>
                        <Text className="card__event--content-region">
                            {`${event?.region} - ${findCountry(event?.country).nativeName} `}
                        </Text>
                    </Space>
                )}
                description={(
                    <Space direction="vertical" size={12}>
                        <Space direction="horizontal" size={8}>
                            {
                                event?.isNext && (
                                    <>
                                        <Text className="card__event--content-time">
                                            <span>{t('IS_NEXT')}</span>
                                        </Text>
                                        <span>-</span>
                                    </>
                                )
                            }
                            <Text className="card__event--content-time">
                                {`${date} ${t('AT')} ${time}`}
                            </Text>
                        </Space>
                        <Button
                            type="ghost"
                            className="card__event--content-button"
                            onClick={handleLink}
                        >
                            {t('JOIN')}
                        </Button>
                    </Space>
                )}
            />
        </div>
    );
};

export default EventCard;
