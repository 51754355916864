import { useTranslation } from 'react-i18next';
import Portrait from '../components/Schedule/Portrait';

const Schedule = () => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME.SCHEDULE' });
    return (
        <div className="schedule">
            <Portrait t={t} />
        </div>
    );
};

export default Schedule;
