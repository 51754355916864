import { Tabs, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import Leadership from './Leadership';
import Members from './Members';
import Secretary from './Secretary';

interface Props {
    form: any;
    group: any;
}

const InviteMembers = ({ form, group }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const RULE = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { min: 3, message: tf('RULES.MIN_LENGTH', { length: 3 }) },
    ];

    const RULES_EMAIL: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { type: 'email', message: tf('RULES.INVALID_EMAIL') },
        { type: 'string', min: 3, message: tf('RULES.MIN_LENGTH', { length: 3 }) },
    ];

    const items = [
        {
            key: 'item-1',
            label: tf('INPUTS.LEADERSHIP'),
            children: <Leadership tf={tf} rules={RULE} rulesEmail={RULES_EMAIL} form={form} />,
        },
        {
            key: 'item-2',
            label: tf('INPUTS.SECRETARY'),
            children: <Secretary tf={tf} rules={RULE} rulesEmail={RULES_EMAIL} form={form} />,
        },
        {
            key: 'item-3',
            label: tf('INPUTS.MEMBERS'),
            children: <Members tf={tf} rules={RULE} rulesEmail={RULES_EMAIL} form={form} group={group} />,
        },
    ];

    return (
        <Form
            form={form}
            layout="vertical"
            name="invite-members"
        >
            <Tabs
                items={items}
                centered
            />
        </Form>
    );
};

export default InviteMembers;
