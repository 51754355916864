import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FaWindowClose } from 'react-icons/fa';
import { IStep } from '../../interfaces';
import ContentWrapper from '../../widgets/ContentWrapper';
import Steps from './Steps';

const StepsManager = ({ steps }: { steps: IStep[]}) => {
    const { t } = useTranslation('dashboard', { keyPrefix: 'ONBOARDING' });
    const navigate = useNavigate();
    return (
        <ContentWrapper title={t('STUDY_GROUPS')}>
            <FaWindowClose
                size={32}
                color="#86C3FF"
                className="icon__close"
                onClick={() => navigate('/')}
            />
            <Steps
                t={t}
                steps={steps}
            />
        </ContentWrapper>
    );
};

export default StepsManager;
