import axios from 'axios';
import { BOOK_API } from '../config';

const getExperiences = (query: any) => {
    const searchParams = new URLSearchParams(query).toString();
    const url = `${BOOK_API}/experiences?${searchParams}`;
    const response = axios.get(url);
    return response;
};

export default {
    getExperiences,
};
