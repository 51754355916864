import {
    Avatar, List, Space, Typography,
} from 'antd';
import { IMember } from '../interfaces';
import { COUNTRIES } from '../utils/countries.util';

interface Props {
    member: IMember;
}

const { Item } = List;
const { Text, Title } = Typography;

const MemberCard = ({ member }: Props) => {
    const findImage = (country: string) => COUNTRIES.filter((item: any) => item.code === country)[0].flag;

    return (
        <div
            key={member?.name}
            className="home__section--experiences-card"
        >
            <Item.Meta
                avatar={
                    member?.image ? (
                        <Avatar
                            size={64}
                            src={member?.image.original}
                            className="home__section--feedback-card-avatar"
                        />
                    ) : (
                        <Avatar
                            size={48}
                            className="home__section--feedback-card-avatar"
                        >
                            {member.name.charAt(0).toUpperCase()}
                        </Avatar>
                    )
                }
                title={(
                    <Title level={4} className="home__section--feedback-card-title">
                        {`${member.name} ${member.lastName}`}
                    </Title>
                )}
                description={(
                    <Space direction="vertical" size={12}>
                        <Space direction="horizontal" size={8}>
                            <Avatar
                                className="home__section--feedback-card-fixed-flag"
                                src={findImage(member?.country)}
                                shape="square"
                            />
                            <Text
                                className="home__section--feedback-card-description"
                                style={{ whiteSpace: 'break-spaces' }}
                            >
                                {member.jobPosition}
                            </Text>
                        </Space>
                        <Text className="home__section--feedback-card-date">
                            {member.email}
                        </Text>
                    </Space>
                )}
            />
        </div>
    );
};

export default MemberCard;
