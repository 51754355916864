import moment from 'moment';
import {
    Avatar, Button, List, Space, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { IReview } from '../../interfaces';

interface Props {
    item: IReview;
    handleSetReview: (review: IReview) => void;
}

const { Title, Text } = Typography;

const ReviewCard = ({ ...props }: Props) => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME.REVIEWS' });

    const { item, handleSetReview } = props;

    return (
        <div className="home__section--review-card">
            <List.Item.Meta
                className="full__width"
                avatar={
                    item?.picture?.original ? (
                        <Avatar
                            size={48}
                            src={item?.picture?.original}
                            className="home__section--feedback-card-avatar"
                        />
                    ) : (
                        <Avatar
                            size={48}
                            className="home__section--feedback-card-avatar"
                        >
                            {item.name.charAt(0).toUpperCase()}
                        </Avatar>
                    )
                }
                title={(
                    <Title level={5} className="home__section--feedback-card-title no__margin">
                        {`${item.name} ${item.lastName}`}
                    </Title>
                )}
                description={(
                    <Text className="home__section--feedback-card-description">
                        {item.jobPosition}
                    </Text>
                )}
            />
            <Space direction="vertical" size={8}>
                <Title level={5} className="home__section--feedback-card-title no__margin">
                    <div dangerouslySetInnerHTML={{ __html: item.title }} />
                </Title>
                <Text className="home__section--review-card-description">
                    <div dangerouslySetInnerHTML={{ __html: item.review }} />
                </Text>
                <Text
                    onClick={() => handleSetReview(item)}
                    style={{ color: '#86C3FF', fontSize: 16, cursor: 'pointer' }}
                >
                    {t('READ_MORE')}
                </Text>
            </Space>
            <Text className="home__section--feedback-card-date">
                {moment(item.publishDate || item.createdAt).format('DD MMM HH:mm')}
            </Text>
        </div>
    );
};

export default ReviewCard;
