import {
    Form, Input, notification, Space, Spin, Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Country from '../../widgets/Country';
import PhoneNumber from '../../widgets/PhoneNumber';
import usersAPI from '../../api/users.api';

interface Props {
    addedMember: (member: any) => void;
}

const { Title } = Typography;

const ExternalMember = ({ addedMember }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const RULE = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { min: 3, message: tf('RULES.MIN_LENGTH', { length: 3 }) },
    ];

    const RULES_EMAIL: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { type: 'email', message: tf('RULES.INVALID_EMAIL') },
        { type: 'string', min: 3, message: tf('RULES.MIN_LENGTH', { length: 3 }) },
    ];

    const createUser = () => {
        setLoading(true);
        const values = form.getFieldsValue();
        const payload = {
            ...values,
            fullMobile: `${values.countryCode}${values.mobile}`,
        };
        usersAPI.createUser(payload)
            .then((response: any) => {
                const { data } = response;
                notification.success({
                    message: tf('RESPONSES.CREATED_USER'),
                });
                addedMember(data);
            })
            .catch(() => {
                notification.error({
                    message: tf('RESPONSES.CREATED_USER_FAILED'),
                });
            })
            .finally(() => {
                form.resetFields();
                setLoading(false);
            });
    };

    return (
        <Space direction="vertical" size={24}>
            <Title level={3}>
                {tf('HEADER.EXTERNAL_MEMBER')}
            </Title>
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item name="password" hidden initialValue="password">
                    <Input />
                </Form.Item>

                <Form.Item name="role" hidden initialValue={['MEMBER_STUDY_GROUP']}>
                    <Input />
                </Form.Item>

                <Form.Item name="origin" hidden initialValue="WEB">
                    <Input />
                </Form.Item>

                <Form.Item
                    label={tf('INPUTS.NAME')}
                    name="name"
                    rules={RULE}
                >
                    <Input placeholder={tf('INPUTS.NAME_PLACEHOLDER')} />
                </Form.Item>

                <Form.Item
                    label={tf('INPUTS.LAST_NAME')}
                    name="lastName"
                    rules={RULE}
                >
                    <Input placeholder={tf('INPUTS.LAST_NAME_PLACEHOLDER')} />
                </Form.Item>

                <Country name="country" />

                <PhoneNumber countryCodeName="countryCode" mobileName="mobile" />

                <Form.Item
                    label={tf('INPUTS.EMAIL')}
                    name="email"
                    rules={RULES_EMAIL}
                >
                    <Input placeholder={tf('INPUTS.EMAIL_PLACEHOLDER')} />
                </Form.Item>

                <span
                    className="alternal-link"
                    style={{
                        pointerEvents: loading ? 'none' : 'auto',
                    }}
                    onClick={() => createUser()}
                >
                    {loading ? <Spin /> : null}
                    {tf('ACTIONS.ADD_MEMBER')}
                </span>
            </Form>
        </Space>
    );
};

export default ExternalMember;
