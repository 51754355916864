import cs from 'classnames';
import {
    Space, Typography, Row, Col, Avatar,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IMAGES from '../../constants/images';

const { Title, Text, Paragraph } = Typography;

const Author = () => {
    const { t } = useTranslation('home', { keyPrefix: 'HOME' });
    const [active, isActive] = useState<boolean>(false);

    return (
        <div className="home__section home__section--author-data" id="author">

            <Row gutter={[24, 32]} justify="space-between" align="middle">
                <Col xs={24} xl={12}>

                    <Space direction="vertical" size={24}>
                        <Title level={1}>
                            {t('AUTHOR.HEADER')}
                        </Title>

                        <Text className="home__section--author-name">
                            {t('AUTHOR.NAME')}
                        </Text>

                        <Space direction="vertical" size={8}>
                            <Paragraph
                                className={cs('home__section--author-description', { active })}
                            >
                                {t('AUTHOR.BIBLIOGRAPHY')}
                            </Paragraph>
                            <Text
                                className="home__section--more"
                                onClick={() => isActive(!active)}
                            >
                                {t(!active ? 'PORTRAIT.READ_MORE' : 'PORTRAIT.READ_LESS')}
                            </Text>
                        </Space>

                        <div className="home__section--author-data--brands">
                            <Avatar
                                className="home__section--author-data--brands-avatar-qr"
                                src={IMAGES.vcard}
                                shape="square"
                                size={120}
                            />
                            <Avatar
                                className="home__section--author-data--brands-avatar"
                                src={IMAGES.logo.rectoral}
                                shape="square"
                            />
                        </div>
                    </Space>
                </Col>
                <Col
                    xs={24}
                    xl={12}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Avatar
                        style={{ margin: '0 auto' }}
                        src={IMAGES.author}
                        size={400}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Author;
