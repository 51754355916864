import Author from '../components/Author';
import Available from '../components/Available';
import Contact from '../components/Contact';
import Feedback from '../components/Feedback';
import InvitationGroups from '../components/InvitationGroups';
import Portrait from '../components/Portrait';
import { ExperiencesProvider } from '../components/Experiences/context/experiencesContext';
import Experiences from '../components/Experiences';
import ChaptersReviews from '../components/ChaptersReviews';

const Home = () => (
    <div className="home">
        <Contact />
        <Portrait />
        <ExperiencesProvider>
            <Experiences />
        </ExperiencesProvider>
        <InvitationGroups />
        <ChaptersReviews />
        <Author />
        <Available />
        <Feedback />
    </div>
);

export default Home;
