import axios from 'axios';
import { BOOK_API } from '../config';

const createStudyGroup = (payload: any) => {
    const lang = localStorage.getItem('i18nextLng')?.toUpperCase() || 'EN';
    const url = `${BOOK_API}/study-groups?lang=${lang}`;
    const response = axios.post(url, payload);
    return response;
};

const validateStudyGroup = (params: any) => {
    const lang = localStorage.getItem('i18nextLng')?.toUpperCase() || 'EN';
    const urlParams = new URLSearchParams({ ...params, lang }).toString();
    const url = `${BOOK_API}/study-groups/validate-study-group?${urlParams}`;
    const response = axios.get(url);
    return response;
};

export default {
    createStudyGroup,
    validateStudyGroup,
};
