import axios from 'axios';
import { DASHBOARD_API } from '../config';

const uploadImageToS3 = (file: any, type: any) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', type);

    const url = `${DASHBOARD_API}/users/upload-image`;
    const headers = {
        'Content-Type': 'multipart/form-data',
    };

    const response = axios.post(url, formData, { headers });
    return response;
};

export default {
    uploadImageToS3,
};
